import { OfferDetails_Data } from "../../constants/Common"

const initialState = {
  offerdetailsData: []
}

const OfferDetailsData = (state = initialState, action) => {
  switch (action.type) {
    case OfferDetails_Data:
      return { ...state, offerdetailsData: action.data }
    default:
      return state
  }
}

export default OfferDetailsData
