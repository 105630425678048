import {
  GetEshopData,
  IsInquiryModalOpen,
  IsPreviewEshop,
  ISStoreDetailModal,
  TwoDates,
  NumOfPersons,
  PriceCalculation
} from "../../constants/EShopConstants"

// ** Initial State
const initialState = {
  eshopdata: [],
  isPreviewStore: false,
  isInquiryModalOpen: false,
  isStoreDetailModal:false,
  twoDates:null,
  numOfPersons:1,
  priceCalculation:1
}

const GroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GetEshopData:
      return { ...state, eshopdata: action.data }
    case IsPreviewEshop:
      return { ...state, isPreviewStore: action.data }
    case IsInquiryModalOpen:
      return { ...state, isInquiryModalOpen: action.data }
      case ISStoreDetailModal:
        return { ...state, isStoreDetailModal: action.data }
        case TwoDates:
          return { ...state, twoDates: action.data }  
          case NumOfPersons:
            return { ...state, numOfPersons: action.data }
            case PriceCalculation:
              return { ...state, priceCalculation: action.data } 
    default:
      return state
  }
}

export default GroupReducer
