import {
  GET_AIITINERARY_TRIPS,
  GET_ITINERARY_GUID,
  GET_STATIC_AIITINERARY_TRIPS,
  GET_USER_AIITINERARY_TRIPS,
  IS_Ai_Price_Modal,
  IS_ITINERARY_LOADED,
  IS_USER_AIITINERARY_TRIPS
} from "../../constants/Common"

const initialState = {
  ItineraryGuid: "",
  isItineraryLoaded: false,
  AiTrips: [],
  StaticAiTrips: [],
  UserAITrips: [],
  isUserItineraryTrips: false,
  isAiPriceModal: false
}

const itineraryApi = (state = initialState, action) => {
  switch (action.type) {
    case GET_ITINERARY_GUID:
      return { ...state, ItineraryGuid: action.data }
    case IS_ITINERARY_LOADED:
      return { ...state, isItineraryLoaded: action.data }
    case GET_AIITINERARY_TRIPS:
      return { ...state, AiTrips: action.data }
    case GET_STATIC_AIITINERARY_TRIPS:
      return { ...state, StaticAiTrips: action.data }
    case GET_USER_AIITINERARY_TRIPS:
      return { ...state, UserAITrips: action.data }
    case IS_USER_AIITINERARY_TRIPS:
      return { ...state, isUserItineraryTrips: action.data }
    case IS_Ai_Price_Modal:
      return { ...state, isAiPriceModal: action.data }
    default:
      return state
  }
}

export default itineraryApi
