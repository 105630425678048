// ** Redux Imports
import { combineReducers } from "redux"

// ** Reducers Imports
import auth from "./auth"
import navbar from "./navbar"
import layout from "./layout"
import tripdata from "./tripdata"
import trip from "./trips"
import group from "./groups"
import common from "./common"
import mediagallery from "./mediagallery"
import modalsdata from "./modalsData"
import tripsgroups from "./tripsgroups"
import createtripdata from "./createTrip"
import adminpanel from "./adminpanel"
import loading from "./loading"
import tripinfo from "./tripinfo"
import itinerary from "./common/itineraryapi"
import contacts from "./common/contacts"
import offerdetails from "./common/offerdetails"
import organization from "./organizations"
import eshop from "./EShop"
import ai from "./Ai"
import createpayment from "./createpayment"


const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  tripdata,
  trip,
  group,
  common,
  mediagallery,
  modalsdata,
  tripsgroups,
  createtripdata,
  adminpanel,
  loading,
  tripinfo,
  itinerary,
  contacts,
  organization,
  eshop,
  ai,
  offerdetails,
  createpayment
})

export default rootReducer
