import React, { useEffect, useState } from "react"
import { Badge, Button, Col, Row } from "reactstrap"
import Select from "react-select"
import LanguageList from "../../../assets/data/locales/languages.json"
import TranslateLanguageApi from "../../CommonApis/TranslatorApi/TranslateLanguageApi"
import { useDispatch, useSelector } from "react-redux"
import { GetLanuageTitle } from "../CommFunctions/GetLanguageTitle"
import DetectLanguage from "../../CommonApis/TranslatorApi/DetectLanguageApi"
import {
  GET_CURRENT_DROP_DOWN_LANG_CODE,
  GET_CURRENT_LANG_CODE
} from "../../../redux/constants/Common"
const TempLanguageField = ({
  setTempData,
  TempData,
  DisableMode,
  createScreen
}) => {
  const { languagecode, curentDropdownLanguageCode, MapsLoc } = useSelector(
    (state) => state.common
  )
  const [comStates, setComStates] = useState({
    languageName: GetLanuageTitle(curentDropdownLanguageCode),
    languageCode: curentDropdownLanguageCode
  })
  useEffect(() => {
    setComStates({
      ...comStates,
      languageCode: curentDropdownLanguageCode,
      languageName: GetLanuageTitle(curentDropdownLanguageCode)
    })
  }, [curentDropdownLanguageCode])
  const { languageName, languageCode } = comStates
  const {
    description,
    additionalInfo,
    LocationTag,
    locationTag,
    TripLocationTag,
    MapsLocationTag,
    tripDescription
  } = TempData
  console.log(TempData)
  const dispatch = useDispatch()
  const Translator = () => {
    const filteredData = [
      description,
      additionalInfo,
      LocationTag || locationTag,
      TripLocationTag
    ].filter((element) => {
      if (element !== undefined || element !== null) {
        return element
      }
    })
    TranslateLanguageApi(filteredData, languageCode)
      .then((response) => {
        DetectLanguage(response[0]?.translatedText, dispatch)
          .then((response) => {
            dispatch({
              type: GET_CURRENT_LANG_CODE,
              data: response
            })
          })
          .catch((err) => {
            console.log(err)
          })
        if (description === null || description === undefined) {
          setTempData({
            ...TempData,
            additionalInfo: response[0]?.translatedText
          })
        } else {
          if (additionalInfo === null || additionalInfo === undefined) {
            if (LocationTag || locationTag !== MapsLoc) {
              if (createScreen) {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText,
                  tripDescription: response[0]?.translatedText,
                  LocationTag: LocationTag ? response[1].translatedText : null,
                  locationTag: LocationTag ? response[1].translatedText : null,
                  TripLocationTag: response[1].translatedText
                })
              } else {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText,
                  LocationTag: LocationTag ? response[1].translatedText : null,
                  locationTag: LocationTag ? response[1].translatedText : null
                })
              }
            } else {
              if (createScreen) {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText,
                  tripDescription: response[0]?.translatedText
                })
              } else {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText
                })
              }
            }
          } else {
            if (LocationTag || locationTag !== MapsLoc) {
              if (createScreen) {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText,
                  tripDescription: response[0]?.translatedText,
                  additionalInfo: response[1]?.translatedText,
                  LocationTag: LocationTag ? response[2].translatedText : "",
                  locationTag: LocationTag ? response[2].translatedText : "",
                  TripLocationTag: LocationTag ? response[2].translatedText : ""
                })
              } else {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText,
                  additionalInfo: response[1]?.translatedText,
                  LocationTag: LocationTag ? response[2].translatedText : "",
                  locationTag: LocationTag ? response[2].translatedText : ""
                })
              }
            } else {
              if (createScreen) {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText,
                  tripDescription: response[0]?.translatedText,
                  additionalInfo: response[1]?.translatedText
                })
              } else {
                setTempData({
                  ...TempData,
                  description: response[0]?.translatedText,
                  additionalInfo: response[1]?.translatedText
                })
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  return (
    <Col>
      <Row>
        {!createScreen && (
          <Col lg="6">
            <p style={{ marginTop: "6px" }}>
              Current Description Language :{" "}
              <Badge>{`${GetLanuageTitle(
                GetLanuageTitle(languagecode)
              )} (${GetLanuageTitle(languagecode)})`}</Badge>
            </p>
          </Col>
        )}

        <Col lg={createScreen ? "6" : "3"} className="mb-2">
          <div>
            <Select
              name="code"
              options={LanguageList}
              value={languageName}
              onChange={(e) => {
                setComStates({
                  ...comStates,
                  languageName: e.name,
                  languageCode: e.code
                })
                dispatch({
                  type: GET_CURRENT_DROP_DOWN_LANG_CODE,
                  data: e.code
                })
              }}
              placeholder={languageName}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              isDisabled={DisableMode}
            />
          </div>
        </Col>
        <Col lg={createScreen ? "6" : "3"}>
          <Button.Ripple
            disabled={!description && !additionalInfo}
            onClick={() => Translator()}
            className="w-100"
            color="primary"
          >
            Translate
          </Button.Ripple>
        </Col>
      </Row>
    </Col>
  )
}

export default TempLanguageField
