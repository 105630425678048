import { IS_CONTACT_IMPORT_MODAL } from "../../constants/Common"

const initialState = {
  isContactImportModal: false
}

const ContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_CONTACT_IMPORT_MODAL:
      return { ...state, isContactImportModal: action.data }
    default:
      return state
  }
}

export default ContactsReducer
