import { CLOSE_GALLERY_PERMANENT, IS_CLOSE_GALLERY } from "../../../redux/constants/Common"

export const OpenCloseGallery = (dispatch, isCloseGallery) => {
    dispatch({
        type:CLOSE_GALLERY_PERMANENT,
        data:true
    })
    dispatch({
        type:IS_CLOSE_GALLERY,
        data:!isCloseGallery
    })
}