import axios from "axios"
import { toast } from "react-toastify"
import { TranslatorApi } from "../../../utility/ApiKeys/ApiKey"

const TranslateLanguageApi = async (description, languagecode) => {
  const postData = {
    q: description[0] === null ? description[1] : description,
    target: languagecode,
    format: "text"
  }
  return new Promise(function (resolve, reject) {
    const otmAPI = `https://translation.googleapis.com/language/translate/v2?key=${TranslatorApi}`
    axios
      .post(otmAPI, postData)
      .then((response) => resolve(response.data?.data?.translations))
      .catch(function (err) {
        toast.error("Sorry this language is not supported")
        console.log("Fetch Error :-S", err)
      })
  })
}

export default TranslateLanguageApi
