// **  Initial State
const initialState = {
    TripData: {}
  }
  
  const tripDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'TRIPDATA':
        return {
          ...state,
          TripData: action.data
        }
      default:
        return state
    }
  }
  
  export default tripDataReducer
  