import { CREATE_PAYMENT_DIALOG, CREATE_PAYMENT_SELECTED_ITEM, IS_BOOKING_LOADED, IS_PAYMENT_LOADED, IS_CREATE_PAYMENT_ENABLE } from "../../constants/CreatePaymentConstants"
  
  // ** Initial State
  const initialState = {
    iscreatePaymentDailog: false,
    cpItem:[],
    isPaymentLoaded:true,
    isBookingLoaded:true,
    iscreatePaymentEnable:false
  }
  
  const CreatePayment = (state = initialState, action) => {
    switch (action.type) {
      case CREATE_PAYMENT_DIALOG:
        return { ...state, iscreatePaymentDailog: action.data }
      case CREATE_PAYMENT_SELECTED_ITEM:
        return { ...state, cpItem: action.data }
      case IS_PAYMENT_LOADED:
        return { ...state, isPaymentLoaded: action.data }
      case IS_BOOKING_LOADED:
        return { ...state, isBookingLoaded: action.data }
      case IS_CREATE_PAYMENT_ENABLE:
        return { ...state, iscreatePaymentEnable: action.data }             
      default:
        return state
    }
  }
  
  export default CreatePayment
  