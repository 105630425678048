import { Badge } from "reactstrap"
import {
  WaypointTagTyps,
  WaypointTagTypeColors
} from "../../enums/WayPointTagTypes"
import {
  DEFAULT_ACTIVITY_TYPE_0,
  DEFAULT_ACTIVITY_TYPE_1,
  DEFAULT_ACTIVITY_TYPE_2,
  DEFAULT_ACTIVITY_TYPE_3,
  DEFAULT_ACTIVITY_TYPE_4,
  DEFAULT_ACTIVITY_TYPE_5,
  DEFAULT_ACTIVITY_TYPE_6,
  DEFAULT_ACTIVITY_TYPE_7,
  DEFAULT_TRIP_ICON,
  WAYPOINT_END_ICON,
  WAYPOINT_REGULAR_ICON,
  WAYPOINT_START_ICON
} from "../../GlobalAssets/AssetsList"
import * as Icons from "react-feather"
import { MdOutlinePending } from "react-icons/md"
import { BiLogIn } from "react-icons/bi"
import { AiOutlineLogout, AiOutlineInfoCircle } from "react-icons/ai"
import moment from "moment"
import { SERVER_DATE_FORMAT } from "../../GlobalDate/Date"
import { useSelector } from "react-redux"

const bySearch = (user, search) => {
  if (search) {
    return user.Name.toLowerCase().includes(search.toLowerCase())
  } else return user
}
function bySearchWay(way, search) {
  if (search) {
    if (way.Description !== null) {
      return (
        way.Description.toLowerCase().includes(search.toLowerCase()) ||
        way.SearchTag.toLowerCase().includes(search.toLowerCase())
      )
    }
    if (way.SearchTag) {
      return way.SearchTag.toLowerCase().includes(search.toLowerCase())
    }
  } else return way
}

export const filteredListContact = (userList, search) => {
  return userList?.filter((user) => bySearch(user, search))
}

export function filteredListTemplates(wayList, search) {
  return wayList.filter((way) => bySearchWay(way, search))
}

// export const functionThatResetsTheFileInput = () => {
//     const randomString = Math.random().toString(36)
//     this.setState({ inputkey: randomString })
//     this.setState({ imageTrip: null })
//   }

export function CheckIfNoWatchAdmin(
  parties,
  contacts,
  RegisterdUsers,
  nonRegisterdUsers
) {
  const updatedAddPartiList = [...parties]
  const updatedAddContactList = [...contacts]
  RegisterdUsers = updatedAddPartiList.filter((p) => p.IsRegistered === true)
  nonRegisterdUsers = updatedAddPartiList.filter(
    (p) => p.IsRegistered === false
  )
  updatedAddContactList.filter((o1) =>
    updatedAddPartiList.some((o2) => o1.PhoneNumber === o2.PhoneNumber)
  )
  // if (partiRef.current.length === 0) {
  //     updatedAddPartiList = []
  //     RegisterdUsers = []
  //     nonRegisterdUsers = []
  // }
  return { RegisterdUsers, nonRegisterdUsers }
}

export const countf = (oid) => {
  if (oid >= 9001) {
    return <Badge color="success">Custom</Badge>
  }
  switch (oid) {
    case -1:
      return <Badge color="success">Start</Badge>
    // case 0: return <Badge color='success'>Start</Badge>
    case 1001:
      return <Badge color="success">End</Badge>
    // case 9001: return <Badge color='success'>Custom</Badge>

    default:
      return <Badge color="success">{oid}</Badge>
  }
}

export const countTagType = (oid) => {
  if (oid >= 9001) {
    return <Badge color="success">Custom</Badge>
  }
  switch (oid) {
    case 0:
      return <Badge style={{ backgroundColor: "#b2c7cb" }}>No tag</Badge>
    case 1:
      return <Badge style={{ backgroundColor: "#29b1db" }}>Must see</Badge>
    case 2:
      return <Badge style={{ backgroundColor: "#29b1db" }}>Meet here</Badge>
    case 3:
      return <Badge style={{ backgroundColor: "#29b1db" }}>Local sight</Badge>
    case 4:
      return <Badge style={{ backgroundColor: "#29b1db" }}>Interesting</Badge>
    case 5:
      return (
        <Badge style={{ backgroundColor: "#29b1db" }}>Not Interesting</Badge>
      )
    case 6:
      return (
        <Badge style={{ backgroundColor: "#29b1db" }}>Local attraction</Badge>
      )
    case 7:
      return <Badge style={{ backgroundColor: "#29b1db" }}>Museum</Badge>
    case 8:
      return <Badge style={{ backgroundColor: "#29b1db" }}>Take a photo</Badge>
    case 20:
      return <Badge style={{ backgroundColor: "#f19a9a" }}>Avoid</Badge>
    case 21:
      return <Badge style={{ backgroundColor: "#f19a9a" }}>Traffic jam</Badge>
    case 22:
      return <Badge style={{ backgroundColor: "#f19a9a" }}>Bad road</Badge>
    case 23:
      return <Badge style={{ backgroundColor: "#f19a9a" }}>Road accident</Badge>
    case 24:
      return (
        <Badge style={{ backgroundColor: "#f19a9a" }}>Construction works</Badge>
      )
    case 25:
      return (
        <Badge style={{ backgroundColor: "#f19a9a" }}>Police on the road</Badge>
      )
    case 31:
      return <Badge style={{ backgroundColor: "#8588e5" }}>Food, Eat</Badge>
    case 32:
      return (
        <Badge style={{ backgroundColor: "#8588e5" }}>Coffee Bar, Drink</Badge>
      )
    case 33:
      return (
        <Badge style={{ backgroundColor: "#8588e5" }}>Nightlife area</Badge>
      )
    case 34:
      return <Badge style={{ backgroundColor: "#8588e5" }}>Shopping area</Badge>
    case 35:
      return <Badge style={{ backgroundColor: "#25da91" }}>Good for kids</Badge>
    case 36:
      return <Badge style={{ backgroundColor: "#25da91" }}>Pets friendly</Badge>
    case 37:
      return <Badge style={{ backgroundColor: "#8588e5" }}>Souvenir shop</Badge>
    case 38:
      return <Badge style={{ backgroundColor: "#8588e5" }}>Beach bar</Badge>
    case 39:
      return <Badge style={{ backgroundColor: "#8588e5" }}>Market place</Badge>
    case 41:
      return <Badge style={{ backgroundColor: "#A15D98" }}>Parking spot</Badge>
    case 42:
      return (
        <Badge style={{ backgroundColor: "#A15D98" }}>
          EV charging station
        </Badge>
      )
    case 43:
      return <Badge style={{ backgroundColor: "#A15D98" }}>Rent-a-bike</Badge>
    case 44:
      return <Badge style={{ backgroundColor: "#A15D98" }}>Rent-a-boat</Badge>
    case 45:
      return <Badge style={{ backgroundColor: "#A15D98" }}>Rent-a-car</Badge>
    case 46:
      return (
        <Badge style={{ backgroundColor: "#A15D98" }}>Transport spot</Badge>
      )
    case 81:
      return <Badge style={{ backgroundColor: "#25da91" }}>Sea & beach</Badge>
    case 82:
      return <Badge style={{ backgroundColor: "#25da91" }}>Park & stroll</Badge>
    case 84:
      return (
        <Badge style={{ backgroundColor: "#25da91" }}>Sport & Recreation</Badge>
      )
    case 85:
      return (
        <Badge style={{ backgroundColor: "#25da91" }}>Take rest spot</Badge>
      )
    case 86:
      return <Badge style={{ backgroundColor: "#25da91" }}>Bike friendly</Badge>
    case 87:
      return (
        <Badge style={{ backgroundColor: "#25da91" }}>Wellness & health</Badge>
      )
    case 101:
      return <Badge style={{ backgroundColor: "#4382BB" }}>Accommodation</Badge>
    case 102:
      return <Badge style={{ backgroundColor: "#4382BB" }}>Free WiFi</Badge>
    case 103:
      return <Badge style={{ backgroundColor: "#4382BB" }}>Info tag</Badge>
    case 104:
      return <Badge style={{ backgroundColor: "#4382BB" }}>Hospital</Badge>
    case 105:
      return <Badge style={{ backgroundColor: "#4382BB" }}>Police office</Badge>
    case 106:
      return <Badge style={{ backgroundColor: "#4382BB" }}>Custom info</Badge>
    case 501:
      return <Badge style={{ backgroundColor: "#F4C815" }}>Photo tag</Badge>
    case 502:
      return (
        <Badge style={{ backgroundColor: "#4382BB" }}>Tourist office</Badge>
      )
    case 999:
      return <Badge style={{ backgroundColor: "#b2c7cb" }}>Unknown</Badge>
    default:
      return <Badge style={{ backgroundColor: "#b2c7cb" }}>{oid}</Badge>
  }
}

export const iconf = (oid) => {
  switch (oid) {
    case -1:
      return (
        <img
          src={WAYPOINT_START_ICON}
          style={{
            width: 100,
            height: 100,
            borderRadius: "5px",
            objectFit: "cover"
          }}
        />
      )
    case 0:
      return (
        <img
          src={WAYPOINT_START_ICON}
          style={{
            width: 100,
            height: 100,
            borderRadius: "5px",
            objectFit: "cover"
          }}
        />
      )
    case 1001:
      return (
        <img
          src={WAYPOINT_END_ICON}
          style={{
            width: 100,
            height: 100,
            borderRadius: "5px",
            objectFit: "cover"
          }}
        />
      )

    default:
      return (
        <img
          src={WAYPOINT_REGULAR_ICON}
          style={{
            width: 100,
            height: 100,
            borderRadius: "5px",
            objectFit: "cover"
          }}
        />
      )
  }
}
export function addOrderIdTripInfo(list) {
  let count = 0
  list.sort(function (x, y) {
    return x.OrderId === -1 ? -1 : y.OrderId === -1 ? 1 : 0
  })
  list.sort(function (x, y) {
    return x.OrderId === 1001 ? 1 : y.OrderId === 1001 ? -1 : 0
  })
  list.filter((way) => {
    if (way.start === 1) {
      way.OrderId = -1
      way.OldOrderId = -1
      way.WaypointType = 1
      return
    }
    if (way.start === 2) {
      way.OrderId = 1001
      way.OldOrderId = 1001
      way.WaypointType = 3
      return
    }
    way.OrderId = count + 1
    way.OldOrderId = count + 1
    way.WaypointType = 2
    count = count + 1
    return way
  })
}
export function addOrderIdNOSE(list) {
  // let lastItemOrderId
  // if (list?.length >= 2) {
  //   lastItemOrderId = list[list.length - 1].OrderId
  // }
  let count = 0
  list.filter((way, index) => {
    if (way.start === 1) {
      way.OrderId = -1
      way.OldOrderId = -1
      way.WaypointType = 1
      way.start = 1
      return way
    }
    if (way.start === 2) {
      way.OrderId = 1001
      way.OldOrderId = 1001
      way.WaypointType = 3
      way.start = 2

      return way
    }
    way.OrderId = count + 1
    way.OldOrderId = count + 1
    way.WaypointType = 2
    way.start = 3

    count = count + 1
    return way
  })
}
export function addOrderId(list, startendDelete, endDelete) {
  let lastItemOrderId
  if (list?.length >= 1) {
    lastItemOrderId = list[list.length - 1].OrderId
  }

  let count = 0
  list.filter((way, index) => {
    if (!startendDelete) {
      if (index === 0) {
        way.OrderId = -1
        way.OldOrderId = -1
        way.WaypointType = 1
        way.start = 1
        return way
      }
    }
    if (!endDelete) {
      if (way.OrderId === lastItemOrderId) {
        way.OrderId = 1001
        way.OldOrderId = 1001
        way.WaypointType = 3
        way.start = 2
        return
      }
    }
    way.OrderId = count + 1
    way.OldOrderId = count + 1
    way.WaypointType = 2
    count = count + 1
    way.start = 3
    return way
  })
}
export const TripActivityIcon = (tripactivity) => {
  switch (tripactivity) {
    case 0:
      return DEFAULT_ACTIVITY_TYPE_0
    case 1:
      return DEFAULT_ACTIVITY_TYPE_1
    case 2:
      return DEFAULT_ACTIVITY_TYPE_2
    case 3:
      return DEFAULT_ACTIVITY_TYPE_3
    case 4:
      return DEFAULT_ACTIVITY_TYPE_4
    case 5:
      return DEFAULT_ACTIVITY_TYPE_5
    case 6:
      return DEFAULT_ACTIVITY_TYPE_6
    case 7:
      return DEFAULT_ACTIVITY_TYPE_7
    case 999:
      return DEFAULT_TRIP_ICON
    default:
      return DEFAULT_TRIP_ICON
  }
}

export const GetTagIconUrl = (tagtype) => {
  let icon = null
  WaypointTagTyps.filter((tag) => {
    if (tag.Enum === tagtype) {
      icon = tag.Icon
    }
  })
  return icon
}

export const GetTagIconColor = (tagtype) => {
  let color = null
  WaypointTagTyps.filter((tag) => {
    if (tag.Enum === tagtype) {
      color = tag.Color
    }
  })
  return color
}

export const TripStatus = (status) => {
  switch (status) {
    case 1:
      return <Badge color="warning">Upcoming</Badge>
    case 2:
      return <Badge color="danger">Completed</Badge>
    case 3:
      return <Badge color="success">Ongoing</Badge>
    case 4:
      return <Badge color="secondary">Inactive</Badge>
    case 5:
      return <Badge color="primary">Time revoked</Badge>
    case 6:
      return <Badge color="primary">Created</Badge>
    case 7:
      return <Badge color="danger">End-time reached</Badge>
    case 8:
      return <Badge color="success">Check-in period</Badge>
    case 20:
      return <Badge color="dark">Delete candidate</Badge>
    case 999:
      return <Badge color="secondary">Unknown</Badge>
    default:
      return <Badge color="secondary">{status}</Badge>
  }
}

export const TripActivity = (tripactivity) => {
  switch (Number(tripactivity)) {
    case 0:
      return <Badge color="secondary">Casual & Everyday</Badge>
    case 1:
      return <Badge color="secondary">Walking, Hiking, Climbing</Badge>
    case 2:
      return <Badge color="secondary">Hangouts, Parties, Events</Badge>
    case 3:
      return <Badge color="secondary">Sightseeing & Tours</Badge>
    case 4:
      return <Badge color="secondary">Cycling, Running, Skiing</Badge>
    case 5:
      return <Badge color="secondary">Carpooling & Roadtrips</Badge>
    case 6:
      return <Badge color="secondary">Conferences & Meetings</Badge>
    case 7:
      return <Badge color="secondary">Hop on-Hop off</Badge>
    case 999:
      return <Badge color="secondary">Unknown</Badge>
    // case 9001: return <Badge color='success'>Custom</Badge>

    default:
      return <Badge color="secondary">{tripactivity}</Badge>
  }
}

export const ParticipantStatus = (status) => {
  switch (status) {
    case 1:
      return <small>Invite not sent</small>
    case 2:
      return <small>Invite sent</small>
    case 3:
      return <small>Invite accepted</small>
    case 4:
      return <small>Invite invite declined</small>
    case 5:
      return <small>Always accepted</small>
    case 6:
      return <small>Participant left</small>
    case 7:
      return <small>Participant removed</small>
    case 8:
      return <small>Account deactivated</small>
    case 9:
      return <small>Invite expired</small>
    case 999:
      return <small>Unknown</small>
    // case 9001: return <Badge color='success'>Custom</Badge>

    default:
      return <Badge color="secondary">{status}</Badge>
  }
}

export const ParticipantStatusBadge = (status) => {
  switch (status) {
    case 1:
      return <Badge color="secondary">Invite not sent</Badge>
    case 2:
      return <Badge color="warning">Invite sent</Badge>
    case 3:
      return <Badge color="success">Invite accepted</Badge>
    case 4:
      return <Badge color="danger">Invite invite declined</Badge>
    case 5:
      return <Badge color="success">Always accepted</Badge>
    case 6:
      return <Badge color="danger">Participant left</Badge>
    case 7:
      return <Badge color="danger">Participant removed</Badge>
    case 8:
      return <Badge color="secondary">Account deactivated</Badge>
    case 9:
      return <Badge color="secondary">Invite expired</Badge>
    case 999:
      return <Badge color="secondary">Unknown</Badge>
    // case 9001: return <Badge color='success'>Custom</Badge>

    default:
      return <Badge color="secondary">{status}</Badge>
  }
}

export const ParticipantStatusIcon = (status) => {
  switch (status) {
    case 1:
      return <Icons.AlertTriangle />
    case 2:
      return <Icons.HelpCircle />
    case 3:
      return <Icons.CheckSquare />
    case 4:
      return <Icons.Circle />
    case 5:
      return <Icons.CheckSquare />
    case 6:
      return <AiOutlineLogout fontSize={25} />
    case 7:
      return <Icons.XCircle />
    case 8:
      return <BiLogIn fontSize={25} />
    case 9:
      return <MdOutlinePending fontSize={25} />
    case 999:
      return <small>No Info</small>
    default:
      return <Badge color="success">{status}</Badge>
  }
}

export const ParticipantRole = (oid) => {
  switch (oid) {
    case 1:
      return <Badge color="warning">Admin</Badge>
    case 2:
      return <Badge color="warning">Co-Admin</Badge>
    case 3:
      return <Badge color="primary">Participant</Badge>
    case 4:
      return <Badge color="success">Watcher</Badge>
    case 999:
      return <Badge color="secondary">Unknown</Badge>

    default:
      return <Badge color="secondary">{oid}</Badge>
  }
}

export const MemberRole = (oid) => {
  switch (oid) {
    case 1:
      return <Badge color="warning">Admin</Badge>
    case 2:
      return <Badge color="warning">Co-Admin</Badge>
    case 3:
      return <Badge color="primary">Member</Badge>
    case 999:
      return <Badge color="secondary">Unknown</Badge>

    default:
      return <Badge color="secondary">{oid}</Badge>
  }
}

export const MemberStatusBadge = (status) => {
  switch (status) {
    case 1:
      return <Badge color="secondary">Invite not sent</Badge>
    case 2:
      return <Badge color="warning">Invite sent</Badge>
    case 3:
      return <Badge color="success">Invite accepted</Badge>
    case 4:
      return <Badge color="danger">Invite invite declined</Badge>
    case 5:
      return <Badge color="success">Always accepted</Badge>
    case 6:
      return <Badge color="danger">Member left</Badge>
    case 7:
      return <Badge color="danger">Member removed</Badge>
    case 8:
      return <Badge color="secondary">Account deactivated</Badge>
    case 9:
      return <Badge color="secondary">Invite expired</Badge>
    case 999:
      return <Badge color="secondary">Unknown</Badge>
    // case 9001: return <Badge color='success'>Custom</Badge>

    default:
      return <Badge color="secondary">{status}</Badge>
  }
}

export const WebUserStatusBadge = (status) => {
  switch (status) {
    case 0:
      return <Badge color="secondary">Not a Web user</Badge>
    case 1:
      return <Badge color="warning">Registration pending</Badge>
    case 2:
      return <Badge color="warning">Invite sent</Badge>
    case 3:
      return <Badge color="success">Active</Badge>
    case 4:
      return <Badge color="danger">Not Active</Badge>

    case 999:
      return <Badge color="secondary">Unknown</Badge>

    default:
      return <Badge color="secondary">{status}</Badge>
  }
}

export const ContactTypeBadge = (type) => {
  switch (type) {
    case 0:
      return <Badge color="dark">Not Set</Badge>
    case 1:
      return <Badge color="danger">Employee</Badge>
    case 2:
      return <Badge color="danger">Manager</Badge>
    case 3:
      return <Badge color="primary">Tour Guide</Badge>
    case 4:
      return <Badge color="primary">Tour Lead</Badge>
    case 5:
      return <Badge color="primary">Tour Assistent</Badge>
    case 6:
      return <Badge color="warning">Travel Agent</Badge>
    case 7:
      return <Badge color="warning">Tour Operator</Badge>
    case 8:
      return <Badge style={{ backgroundColor: "#A15D98" }}>Driver</Badge>
    case 9:
      return <Badge style={{ backgroundColor: "#A15D98" }}>Partner</Badge>

    case 10:
      return <Badge color="success">Client</Badge>

    case 100:
      return <Badge color="dark">Other</Badge>

    case 999:
      return <Badge color="dark">Unknown</Badge>

    default:
      return <Badge color="dark">{type}</Badge>
  }
}

export const memberStatusIcon = (status) => {
  switch (status) {
    case 1:
      return <Icons.AlertTriangle />
    case 2:
      return <Icons.HelpCircle />
    case 3:
      return <Icons.CheckSquare />
    case 4:
      return <Icons.Circle />
    case 5:
      return <Icons.CheckSquare />
    case 6:
      return <AiOutlineLogout fontSize={25} />
    case 7:
      return <Icons.XCircle />
    case 8:
      return <BiLogIn fontSize={25} />
    case 9:
      return <MdOutlinePending fontSize={25} />
    case 999:
      return <small>No Info</small>
    default:
      return <Badge color="success">{status}</Badge>
  }
}

export const CheckBusinessType = (oid) => {
  switch (oid) {
    case 0:
      return <Badge color="secondary">NotSet</Badge>
    case 1:
      return <Badge color="primary">Tour Agency</Badge>
    case 2:
      return <Badge color="primary">Tour Guide </Badge>
    case 3:
      return <Badge color="primary">Event Creator</Badge>
    case 4:
      return <Badge color="primary">Content Creator</Badge>
    case 100:
      return <Badge color="primary">Master</Badge>
    case 999:
      return <Badge color="primary">Unknown</Badge>
    default:
      return <Badge color="secondary">{oid}</Badge>
  }
}

export const CheckSubscriptionType = (oid) => {
  switch (oid) {
    case 1:
      return <Badge color="success">Paid</Badge>
    case 2:
      return <Badge color="secondary">Free Trial</Badge>
    default:
      return <Badge color="secondary">Free Trial</Badge>
  }
}
