import {
  GET_TEMPWAYTAGMODAL_DATA,
  RESET_TEMPWAYTAGMODAL_DATA,
  WAYPOINTS_ORDERING_MODAL
} from "../../constants/modalsData"

// ** Initial State
const initialState = {
  modaldata: [],
  success: false,
  iswaypointorderingmodal: false
}

const GetTempWayTagData = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPWAYTAGMODAL_DATA:
      return { ...state, modaldata: action.data }
    case RESET_TEMPWAYTAGMODAL_DATA:
      return { ...state, modaldata: [] }
    case WAYPOINTS_ORDERING_MODAL:
      return { ...state, iswaypointorderingmodal: action.data }
    default:
      return state
  }
}

export default GetTempWayTagData
