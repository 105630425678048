// **  Initial State
const initialState = {
  userData: {},
  theme: "light",
  Subscription: {},
  userLocation: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        userData: action.data
      }
    case "UPDATE":
      return {
        ...state,
        userData: action.data
      }
    case "SUBSCRIPTION":
      return {
        ...state,
        Subscription: action.data
      }
    case "LOGOUT":
      const obj = { ...action }
      delete obj.type
      return { ...state, userData: {} }
    case "theme":
      return { ...state, theme: action.skin }
    case "Get_User_Location":
      return { ...state, userLocation: action.data }
    default:
      return state
  }
}

export default authReducer
