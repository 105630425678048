// ** React Imports
import { Suspense, lazy } from "react"
import ReactDOM from "react-dom"

// ** Redux Imports
import { Provider } from "react-redux"
import { store } from "./redux/storeConfig/store"

// ** Toast & ThemeColors Context
import { ToastContainer } from "react-toastify"
import { ThemeContext } from "./utility/context/ThemeColors"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Toastify
import "@styles/react/libs/toastify/toastify.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
import * as serviceWorker from "./serviceWorker"
import MediaGallery from "./components/Items/common/MediaGallery"
// ** Lazy load app

const LazyApp = lazy(() => import("./App"))
import { Auth0Provider } from "@auth0/auth0-react"
import { Auth0ClientId, Auth0Domain } from "./utility/ApiKeys/ApiKey"
// GoogleTagManager.initialize("G-5XFNSWWVFN")
ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      domain={Auth0Domain}
      clientId={Auth0ClientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}`,
        returnTo: `${window.location.origin}`
      }}
      options={{ disable_self_service_change_password: true }}
    >
      <Suspense fallback={<Spinner />}>
        <ThemeContext>
          <LazyApp />
          <MediaGallery />
          <ToastContainer newestOnTop />
        </ThemeContext>
      </Suspense>
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
