import { toast } from "react-toastify"

const apiGet = (method, query) => {
    return new Promise(function (resolve, reject) {
        const otmAPI = `https://api.opentripmap.com/0.1/en/places/${method}?apikey=${'5ae2e3f221c38a28845f05b608b55a01f49b4c75337db5dd2bb415b9'}&${query}`
        fetch(otmAPI)
            .then(response => response.json())
            .then(data => resolve(data))
            .catch(function (err) {
                console.log("Fetch Error :-S", err)
            })
    })
}

const GetXID = async(geolat, geolon, setGeoObjectList, limit, setIsDataLoading) => {
    let count = 0
    setIsDataLoading(false)
    await apiGet("radius", `radius=5000&limit=${limit + 15}&offset=${limit}&lon=${geolon}&lat=${geolat}&rate=2&format=count`).then(function (data) {
            count = data.count
    })
    await apiGet("radius", `radius=5000&limit=${limit + 15}&offset=${limit}&lon=${geolon}&lat=${geolat}&rate=2&format=json`).then(function (data) {
        // if (data?.error?.length !== 0) {
        //     setGeoObjectList([])
        //     toast.error("Service is not available at the moment. Please try later. (Too Many Requests)")
        //     return
        // } 
            setIsDataLoading(true)
        if (data.length !== 0) {
            const dataList = {
                data,
                count
            }
            setGeoObjectList(dataList)
        } else {
            setGeoObjectList([])
        }
    })
}
export const GetGeoName = (name, setGeoObjectList, limit, setIsDataLoading) => {
    apiGet("geoname", `name=${name}`).then(function (data) {
        if (data.status === "OK") {
            GetXID(data.lat, data.lon, setGeoObjectList, limit, setIsDataLoading)
        }
    })
}