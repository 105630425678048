import { Modal, ModalBody, ModalHeader, Form } from "reactstrap"
export const GlobalInfoDailog = ({
  setDetailModal,
  detailModal,
  detailDialogData
}) => {
  return (
    <Modal
      isOpen={detailModal}
      toggle={() => setDetailModal(false)}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader toggle={() => setDetailModal(false)}>
        {detailDialogData?.title}
      </ModalHeader>
      <ModalBody>
        <Form className="mt-0 mb-1">{detailDialogData?.message}</Form>
      </ModalBody>
    </Modal>
  )
}

export const GlobalInfoDailogObj = ({ comStates, setComStates }) => {
  return (
    <Modal
      isOpen={comStates?.detailModal}
      toggle={() => setComStates({ ...comStates, detailModal: false })}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => setComStates({ ...comStates, detailModal: false })}
      >
        {comStates?.detailDialogData?.title}
      </ModalHeader>
      <ModalBody>
        <Form className="mt-0 mb-1">
          {comStates?.detailDialogData?.message}
        </Form>
      </ModalBody>
    </Modal>
  )
}

export const GlobalInfoDailogGroup = ({
  setDetailModal,
  detailModal,
  detailDialogData
}) => {
  return (
    <Modal
      isOpen={detailModal}
      toggle={() => setDetailModal(false)}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader toggle={() => setDetailModal(false)}>
        {detailDialogData?.title}
      </ModalHeader>
      <ModalBody>
        <Form className="mt-0 mb-1">{detailDialogData?.message}</Form>
      </ModalBody>
    </Modal>
  )
}
