import {
  IsAiLoginInfoModal,
  IsAiPaymentSuccessModal,
  IsOrganizationUpdateModal,
  IsApiCancelOkayModal,
  IsModerModalApiLoadingDone,
  IsBillingDetailsModal,
  IsBillingDetailsPCloseModal,
  IsApiCancelOkayModal2
} from "../../constants/AiConstants"

// ** Initial State
const initialState = {
  isAiLoginModal: false,
  isAiPaymentSuccessModal: false,
  isOrganizationUpdateModal: false,
  isApiCancelOkayModal: false,
  isApiCancelOkayModal2: false,
  isModerModalApiLoadingDone:false,
  isBillingDetailsModal:false,
  isBillingDetailsPCloseModal:true
}

const GroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case IsAiLoginInfoModal:
      return { ...state, isAiLoginModal: action.data }
    case IsAiPaymentSuccessModal:
      return { ...state, isAiPaymentSuccessModal: action.data }
    case IsOrganizationUpdateModal:
      return { ...state, isOrganizationUpdateModal: action.data }
    case IsApiCancelOkayModal:
      return { ...state, isApiCancelOkayModal: action.data }
      case IsApiCancelOkayModal2:
        return { ...state, isApiCancelOkayModal2: action.data }   
    case IsModerModalApiLoadingDone:
        return { ...state, isModerModalApiLoadingDone: action.data }  
    case IsBillingDetailsModal:
        return { ...state, isBillingDetailsModal: action.data }
    case IsBillingDetailsPCloseModal:
        return { ...state, isBillingDetailsPCloseModal: action.data }         
    default:
      return state
  }
}

export default GroupReducer
