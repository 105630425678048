import { GetMediaGalleryAction } from "../../../redux/actions/mediagallery"
import {
  IS_Media_GalleryOpen,
  SET_MEDIA_PGUID_PMEDIA
} from "../../../redux/constants/MediaGallery"

export const Set_MEDIA_GALLERY_VALUES = (
  dispatch,
  parentGuid,
  parentMedia,
  MediaType
) => {
  dispatch(GetMediaGalleryAction(parentGuid, parentMedia, MediaType))
  dispatch({ type: IS_Media_GalleryOpen, data: true })
  dispatch({
    type: SET_MEDIA_PGUID_PMEDIA,
    data: { parentGuid, parentMedia }
  })
}
