import {
  Auto_Place_Destructuring,
  CLOSE_GALLERY_PERMANENT,
  GALLERY_SEARCH_REDUX,
  GET_CURRENT_DESCRIPTION_LANG_CODE,
  GET_CURRENT_DROP_DOWN_LANG_CODE,
  GET_CURRENT_LANG_CODE,
  GET_MAPS_LOC,
  GET_SOCIAL_LOGINS,
  GetAvailabilityCalendarDates,
  IS_CLOSE_GALLERY,
  IsFindAgencyModal,
  RESET_CURRENT_LANG_CODE,
  SET_GET_TEMPLATE_LIST,
  SET_GET_TEMPLATE_TAGS_LIST,
  SHOW_AI_RESULT,
  SOCIAL_SHARING_MODAL
} from "../../constants/Common"

// ** Initial State
const initialState = {
  languagecode: "en",
  curentDropdownLanguageCode: "en",
  closeGalleryPermanent: false,
  isCloseGallery: true,
  gallerySearchRedux: "",
  templateList: [],
  templateTagsList: [],
  showAIResult: false,
  isDataLoaded: false,
  MapsLoc: "",
  AutoPlaceDt: [],
  selectedDates: "",
  socialLogin: { gSub: null, fSub: null },
  socailsharingmodal: false,
  CanFindAgency: false
}

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_LANG_CODE:
      return { ...state, languagecode: action.data }
    case GET_CURRENT_DROP_DOWN_LANG_CODE:
      return { ...state, curentDropdownLanguageCode: action.data }
    case RESET_CURRENT_LANG_CODE:
      return { ...state, languagecode: "en" }
    case CLOSE_GALLERY_PERMANENT:
      return { ...state, closeGalleryPermanent: action.data }
    case IS_CLOSE_GALLERY:
      return { ...state, isCloseGallery: action.data }
    case GALLERY_SEARCH_REDUX:
      return { ...state, gallerySearchRedux: action.data }
    case SET_GET_TEMPLATE_LIST:
      return { ...state, templateList: action.data }
    case SET_GET_TEMPLATE_TAGS_LIST:
      return { ...state, templateTagsList: action.data }
    case SHOW_AI_RESULT:
      return {
        ...state,
        showAIResult: action.data?.showAIResult,
        isDataLoaded: action.data?.isDataLoaded
      }
    case GET_MAPS_LOC:
      return { ...state, MapsLoc: action.data }
    case Auto_Place_Destructuring:
      return { ...state, AutoPlaceDt: action.data }
    case GetAvailabilityCalendarDates:
      return { ...state, selectedDates: action.data }
    case GET_SOCIAL_LOGINS:
      return { ...state, socialLogin: action.data }
    case SOCIAL_SHARING_MODAL:
      return { ...state, socailsharingmodal: action.data }
    case IsFindAgencyModal:
      return { ...state, CanFindAgency: action.data }
    default:
      return state
  }
}

export default CommonReducer
