import { BASE_STORAGE } from "../GlobalURL/URL"

export const WAYPOINT_START_ICON = `${BASE_STORAGE}/public/web_icons/waypoint_start_web_icon.svg`
export const WAYPOINT_END_ICON = `${BASE_STORAGE}/public/web_icons/waypoint_end_web_icon.svg`
/* export const WAYPOINT_REGULAR_ICON = `${BASE_STORAGE}/public/web_icons/waypoint_regular_web_icon.svg`
export const WAYPOINT_TEMPLATE_ICON = `${BASE_STORAGE}/public/web_icons/waypoint_template_web_icon.svg` */
export const WAYPOINT_REGULAR_ICON = `${BASE_STORAGE}/public/web_icons/waypoint_template_web_icon.svg`
export const WAYPOINT_TEMPLATE_ICON = `${BASE_STORAGE}/public/web_icons/waypoint_template_web_icon.svg`
export const TEMPLATE_TAG_ICON = `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Undefined.svg`

export const DEFAULT_AVATAR_ICON = `${BASE_STORAGE}/public/web_icons/web_avatar_icon.svg`

export const DEFAULT_GROUP_ICON = `${BASE_STORAGE}/public/default_icons/default_group_pic_web.webp`
export const DEFAULT_TRIP_ICON = `${BASE_STORAGE}/public/default_icons/trip_cover_pic_2.webp`

export const DEFAULT_ACTIVITY_TYPE_0 = `${BASE_STORAGE}/public/default_icons/trip_cover_pic_2.webp`
export const DEFAULT_ACTIVITY_TYPE_1 = `${BASE_STORAGE}/public/default_icons/activity_type_hiking_pic6.webp`
export const DEFAULT_ACTIVITY_TYPE_2 = `${BASE_STORAGE}/public/default_icons/activity_type_party_pic.webp`
export const DEFAULT_ACTIVITY_TYPE_3 = `${BASE_STORAGE}/public/default_icons/trip_cover_pic_1.webp`
export const DEFAULT_ACTIVITY_TYPE_4 = `${BASE_STORAGE}/public/default_icons/activity_type_cycling_pic3.webp`
export const DEFAULT_ACTIVITY_TYPE_5 = `${BASE_STORAGE}/public/default_icons/activity_type_carpooling_pic4.webp`
export const DEFAULT_ACTIVITY_TYPE_6 = `${BASE_STORAGE}/public/default_icons/activity_type_conference_pic3.webp`
export const DEFAULT_ACTIVITY_TYPE_7 = `${BASE_STORAGE}/public/default_icons/activity_type_hop_on_hop_off_pic2.webp`


export const BANNER_ACTIVITY_TYPE_0 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_0.webp`
export const BANNER_ACTIVITY_TYPE_1 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_1.webp`
export const BANNER_ACTIVITY_TYPE_2 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_2.webp`
export const BANNER_ACTIVITY_TYPE_3 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_3.webp`
export const BANNER_ACTIVITY_TYPE_4 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_4_v2.webp`
export const BANNER_ACTIVITY_TYPE_5 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_5.webp`
export const BANNER_ACTIVITY_TYPE_6 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_6.webp`
export const BANNER_ACTIVITY_TYPE_7 = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_7.webp`

export const DEFAULT_GROUP_BANNER = `${BASE_STORAGE}/public/image_banners/banner_image_group.webp`
export const DEFAULT_TRIP_BANNER = `${BASE_STORAGE}/public/image_banners/banner_image_activity_type_3.webp`

export const GlobalInfoTextColor = 'text-dark'