import axios from "axios"
import React, { useEffect, useState } from "react"
import { ArrowLeft, ArrowRight, ChevronLeft, Edit, Search } from "react-feather"
import { useSelector } from "react-redux"
import { Col, Container, Button, Input, Spinner } from "reactstrap"
import styles from "../../../styles/ItemsStyle/galleryplace.module.scss"

const ImgGallery = ({
  setTempData,
  TempData,
  ComStatus,
  setWaypointStates,
  WaypointStates
}) => {
  const [comStates, setComStates] = useState({
    isCloseGallery: true,
    filterImages: [],
    DataisLoaded: true
  })
  const [count, setcount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [IsCliked, setIsCliked] = useState(false)
  const [clickedQuery, setClikedQuery] = useState("")
  const { filterImages, DataisLoaded } = comStates
  const { gallerySearch } = TempData
  const isCloseGallery = useSelector((state) => state?.common?.isCloseGallery)
  const getImages = (regular, thumb) => {
    if (ComStatus === "UpdateWaypoint") {
      setTempData({
        ...TempData,
        waypimage: regular,
        thumb,
        regular,
        waypfile: null
      })
    } else if (ComStatus === "Waypoint") {
      setTempData({
        ...TempData,
        wayimage: regular,
        thumb,
        regular,
        wayfile: null
      })
      setWaypointStates({
        ...WaypointStates,
        wayimage: regular,
        wayfile: null,
        regular,
        thumb
      })
    } else if (ComStatus === "CreateTripStartW") {
      setTempData({
        ...TempData,
        addStartImage: regular,
        thumb,
        regular,
        addStartFile: null
      })
    } else if (ComStatus === "CreateTripEndW") {
      setTempData({
        ...TempData,
        addEndImage: regular,
        thumb,
        regular,
        addEndFile: null
      })
    } else if (ComStatus === "CreateTrip") {
      setTempData({
        ...TempData,
        imageTrip: regular,
        fileTrip: null,
        regularTrip: regular,
        thumbTrip: thumb,
        wayfile: null
      })
    } else {
      setTempData({ ...TempData, image: regular, thumb, regular, file: null })
    }
  }
  const GetImages = async (key, page) => {
    const postData = {
      localTime: "string"
    }
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        accept: "*/*"
      }
    }
    // setCurrentPage(page)
    setComStates({ ...comStates, DataisLoaded: false })
    await axios
      .get(
        ` https://api.unsplash.com/search/photos?client_id=_AeRkN65dvOBIJj_3lcliODVJ5Xtb9QNbzTblnng_3c&page=${page}&per_page=30&lang=en&query=${
          key === "" ? "trips" : key?.split(/[, ]+/)
        }`,
        postData,
        axiosConfig
      )
      .then((res) => {
        if (res.data.ShowMsg) {
          toast.error(`${res.data.ErrorMsg}`)
          return null
        } else {
          setcount(res.data.total_pages)
          const regularThumArray = []
          res.data.results.filter((img) => {
            const picked = (({ regular, small }) => ({ regular, small }))(
              img.urls
            )
            picked.width = 100
            regularThumArray.push(picked)
          })
          setComStates({
            ...comStates,
            images: regularThumArray,
            DataisLoaded: true,
            filterImages: regularThumArray
          })
        }
      })
      .then((data) => {})
      .catch((err) => {
        console.log("AXIOS ERROR: ", err)
      })
  }
  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (currentPage !== 0) {
        GetImages(gallerySearch, currentPage)
      }
      if (IsCliked && clickedQuery !== gallerySearch) {
        setCurrentPage(1)
      }
      if (gallerySearch !== "") {
        GetImages(gallerySearch, currentPage)
      }
    }, 500)
    return () => clearTimeout(timeOut)
  }, [currentPage, gallerySearch])
  const handleIncPage = () => {
    setComStates({ ...comStates, DataisLoaded: false })
    setIsCliked(true)
    setClikedQuery(gallerySearch)
    setCurrentPage(currentPage + 1)
  }

  const handleDecPage = () => {
    setComStates({ ...comStates, DataisLoaded: false })
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    } else {
      setCurrentPage(1)
    }
  }
  useEffect(() => {
    GetImages(gallerySearch, currentPage)
  }, [])

  return (
    <div className={isCloseGallery ? styles.gClose : styles.gOpen}>
      <div className={styles.gSection}>
        <div className="mt-2">
          <Input
            value={gallerySearch}
            id="LocationTag"
            name="LocationTag"
            placeholder="Search Image Gallery"
            className={styles.searchGallery}
            onChange={(e) => {
              setTempData({ ...TempData, gallerySearch: e.target.value })
            }}
          />
          <Button.Ripple
            onClick={() => GetImages(gallerySearch)}
            data-tip={"Search Image Gallery"}
            color="primary"
            className={`${styles.GsearchBtn} btn-icon btn-sm`}
          >
            <Search size={16} />
          </Button.Ripple>
          <Container className={styles.ImgCon}>
            {DataisLoaded ? (
              <Col
                style={{ padding: "0px", flex: "100%" }}
                className="row-fluid"
              >
                {count !== 0 ? (
                  filterImages.map((item) => {
                    return (
                      <>
                        <img
                          className={styles.gImg}
                          src={item.small}
                          width={100}
                          style={{ objectFit: "cover" }}
                          height={100}
                          onClick={() => getImages(item.regular, item.small)}
                        />
                      </>
                    )
                  })
                ) : (
                  <div className={styles.ResultNotFound}>
                    <p>No images found</p>
                  </div>
                )}
              </Col>
            ) : (
              <div className={styles.ImgGSpinner}>
                <Spinner color="primary" />
              </div>
            )}
          </Container>
        </div>
        {count !== 0 ? (
          <>
            <div className={styles.paginationBox}>
              <Button.Ripple
                disabled={currentPage === 1 || !DataisLoaded}
                data-tip={
                  isCloseGallery
                    ? "Open Images & Places"
                    : "Close Images & Places"
                }
                className={`btn-icon btn-sm`}
                color="primary"
                onClick={() => {
                  handleDecPage()
                }}
              >
                <ArrowLeft size={16} />
              </Button.Ripple>
              <Button.Ripple
                disabled={currentPage + 1 > count || !DataisLoaded}
                data-tip={
                  isCloseGallery
                    ? "Open Images & Places"
                    : "Close Images & Places"
                }
                className={`btn-icon btn-sm`}
                color="primary"
                onClick={() => {
                  handleIncPage()
                }}
              >
                <ArrowRight size={16} />
              </Button.Ripple>
            </div>
            <div style={{ textAlign: "center", padding: "5px" }}>
              <p>
                Page {currentPage} of {count}
              </p>
            </div>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default ImgGallery
