import { GET_FILTER, GET_OFFER_TYPE } from "../../constants/TripsGroups"

// ** Initial State
const initialState = {
  upcoming: false,
  today: false,
  offerType: ""
}

const TripGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FILTER:
      return {
        ...state,
        upcoming: action.data.upcoming,
        today: action.data.today
      }
    case GET_OFFER_TYPE:
      return {
        ...state,
        offerType: action.data
      }
    default:
      return state
  }
}

export default TripGroupsReducer
