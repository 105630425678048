// ** Logo
import logo from "@src/assets/images/logo/logo.png"
import { Spinner } from "reactstrap"

const SpinnerComponent = ({ image }) => {
  return (
    <div className="fallback-spinner">
      <div>
        <div>
          <img
            src={
              image
                ? image
                : require("../../../assets/images/logo/JellyRide -02_resized.png")
                    .default
            }
            alt="logo"
            width={65}
          />
        </div>
        <div className="spinner mt-1"></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
