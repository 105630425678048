// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3nyascAp51j8GOYCFc9FSE {\n  display: flex;\n  max-width: 1000px;\n  width: 60%; }\n\n._2fUv2Wq9CyCMUTQM4YfcU_ {\n  display: flex;\n  justify-content: start; }\n\n[dir] ._3qR712FPM6_pHx8yaPWHGz {\n  text-align: end; }\n\n._317BxDwnMSso8IUGf2lJUA {\n  display: flex;\n  justify-content: end; }\n\n@media (max-width: 467px) {\n  ._3nyascAp51j8GOYCFc9FSE {\n    width: 100%; }\n  [dir] ._3nyascAp51j8GOYCFc9FSE {\n    margin: 10px auto; } }\n", "",{"version":3,"sources":["webpack://src/styles/Modals/modals.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,UAAU,EAAE;;AAEd;EACE,aAAa;EACb,sBAAsB,EAAE;;AAE1B;EACE,eAAe,EAAE;;AAEnB;EACE,aAAa;EACb,oBAAoB,EAAE;;AAExB;EACE;IACE,WAAW,EACQ;EAFrB;IAEE,iBAAiB,EAAE,EAAE","sourcesContent":[".WideModal {\n  display: flex;\n  max-width: 1000px;\n  width: 60%; }\n\n.SetAtStart {\n  display: flex;\n  justify-content: start; }\n\n.SetTextEnd {\n  text-align: end; }\n\n.ModalGalleryBtnContainer {\n  display: flex;\n  justify-content: end; }\n\n@media (max-width: 467px) {\n  .WideModal {\n    width: 100%;\n    margin: 10px auto; } }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WideModal": "_3nyascAp51j8GOYCFc9FSE",
	"SetAtStart": "_2fUv2Wq9CyCMUTQM4YfcU_",
	"SetTextEnd": "_3qR712FPM6_pHx8yaPWHGz",
	"ModalGalleryBtnContainer": "_317BxDwnMSso8IUGf2lJUA"
};
export default ___CSS_LOADER_EXPORT___;
