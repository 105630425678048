import * as featherIcons from "react-feather"

export const RootData = (skin) => {
    return {
        "& .MuiTablePagination-root ": {
            color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b'
        },
        "& .MuiTypography-caption": {
            color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b'
        },
        "& .MuiSvgIcon-root": {
            color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b'
        },
        "& .MuiTypography-h6": {
            fontWeight: 500,
            fontSize: '1.285rem',
            fontFamily: 'Inter, "Montserrat", Helvetica, Arial, serif',
            color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b'
        },
        "& .MuiSelect-select.MuiSelect-select": {
            color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b'
        },
        "& .MuiSelect-icon": {
            color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b'
        },
        "& .MuiTableCell-root": {
            borderBottom: skin === 'dark' ? '2px solid #343d55' : '',
            height: '65px !important',
            padding: '10px 10px !important',
            overflow: 'hidden'
        },
        "& .MuiTableCell-body": {
            color: skin === 'dark' ? '#b4b7bd' : '#6e6b7b',
            fontSize: '1rem',
            fontFamily: 'Inter, "Montserrat", Helvetica, Arial, serif'
        },
        "& .MuiPaper-root": {
            padding: '20px'
        },
        "& .MuiToolbar-gutters": {
            paddingLeft: '0px'
        }
    }
}

export const HeaderStyle = (skin) => {
    return {
        backgroundColor: skin === 'dark' ? '#343d55' : '#f3f2f7',
        color: skin === "dark" ? '#d0d2d6' : '#6e6b7b',
        textTransform: 'uppercase',
        fontSize: '0.857rem',
        letterSpacing: '0.5px',
        borderBottom: skin === 'dark' ? '2px solid #343d55' : '',
        fontFamily: 'Inter, "Montserrat", Helvetica, Arial, serif',
        fontWeight: 'bold',
        height: '10px !important'
    }
}

export const CellStyle = (skin) => {
    return {
        color: skin === "dark" ? '#d0d2d6' : '#6e6b7b',
        wordWrap: 'break-word',
        fontFamily: 'Inter, "Montserrat", Helvetica, Arial, serif',
        borderBottom: skin === 'dark' ? '2px solid #343d55' : '2px solid #ebe9f1',
        textTransform: 'none'
    }
}

export const MaterialTableTablePagination = (skin) => {
    return {
        ResetSearch: (props) => <featherIcons.X size={16} style={{ color: skin === "dark" ? '#d0d2d6' : '#6e6b7b' }} />,
        NextPage: (props) => <featherIcons.ChevronRight size={16} style={{ color: skin === "dark" ? '#d0d2d6' : '#6e6b7b' }} />,
        PreviousPage: (props) => <featherIcons.ChevronLeft size={16} style={{ color: skin === "dark" ? '#d0d2d6' : '#6e6b7b' }} />,
        LastPage: (props) => <featherIcons.ChevronsRight size={16} style={{ color: skin === "dark" ? '#d0d2d6' : '#6e6b7b' }} />,
        FirstPage: (props) => <featherIcons.ChevronsLeft size={16} style={{ color: skin === "dark" ? '#d0d2d6' : '#6e6b7b' }} />
    }
}

export const MaterialTableOptions = (skin) => {
    return {
        headerStyle:{
            backgroundColor: skin === 'dark' ? '#343d55' : '#f3f2f7',
            color: skin === "dark" ? '#d0d2d6' : '#6e6b7b',
            textTransform: 'uppercase',
            fontSize: '0.857rem',
            letterSpacing: '0.5px',
            borderBottom: skin === 'dark' ? '2px solid #343d55' : '',
            fontFamily: 'Inter, "Montserrat", Helvetica, Arial, serif',
            fontWeight: 'bold',
            height: '10px !important'
        },
        actionsColumnIndex: -1,
        search: true,
        sorting: true,
        pageSize: 5,
        emptyRowsWhenPaging: false,
        pageSizeOptions: [5, 10, 25, 50, 100],
        searchFieldStyle: {
          color: skin === "dark" && "#d0d2d6"
        }
      }
}

// https://cdn.global.jellyride-prod.app/organizations/143/organization-icon-8a09c496e819a069d1175e5f73216cb39306e285.jpeg