import React, { useState } from "react"
import { DelayInput } from "react-delay-input"
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import styles from "../../../styles/template.module.scss"
import { useForm } from "react-hook-form"
import ReactTooltip from "react-tooltip"
import { ChevronLeft, Edit, Trash, X, Image } from "react-feather"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import { FaTimes } from "react-icons/fa"
import FileBase64 from "react-file-base64"
import { GetFile } from "../../common/CommFunctions/GetFile"
import { WaypointTagTyps } from "../../../enums/WayPointTagTypes"
import Select from "react-select"
import GalleryExternalPlaces from "../../Items/common/GalleryExternalPlaces"
import { GetWikipediaData } from "../../Items/common/GetWikipediaData"
import TempLanguageField from "../../common/commonFields/TempLanguageField"
import { useDispatch, useSelector } from "react-redux"
import { OpenCloseGallery } from "../../common/CommFunctions/OpenCloseGallery"
import { GPAutocompleteApi } from "../../../utility/ApiKeys/ApiKey"
const GeoObjectDetailDialog = ({
  onAddTemplate,
  TempData,
  setTempData,
  comBStates,
  ResetAllValuesToNull,
  functionThatResetsTheFileInput,
  updateStartLocation
}) => {
  const [comStates, setComStates] = useState({
    languageName: "English",
    languageCode: "en"
  })
  const { handleSubmit } = useForm()
  const isCloseGallery = useSelector((state) => state?.common?.isCloseGallery)
  const dispatch = useDispatch()

  return (
    <Modal
      className={styles.Modal1}
      isOpen={TempData.Addopen}
      toggle={() => {
        ResetAllValuesToNull()
      }}
    >
      <ModalHeader
        toggle={() => {
          ResetAllValuesToNull()
        }}
      >
        {TempData.isUpdateTag === "yes"
          ? "Add Template Tag"
          : "Add Template Waypoint"}
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onAddTemplate)}>
          {/* <ImgGallery setTempData={setTempData} TempData={TempData} isUpdate={true} isDisable={TempData.DisableMode} /> */}
          <Row style={{ justifyContent: "end" }}>
            <Button.Ripple
              className="btn-icon btn-sm mr-2"
              data-tip={
                !isCloseGallery
                  ? "Open Images & Places"
                  : "Close Images & Places"
              }
              style={{ marginBottom: "20px" }}
              color={`${isCloseGallery ? "danger" : "primary"}`}
              onClick={() => OpenCloseGallery(dispatch, isCloseGallery)}
            >
              <ReactTooltip />
              {isCloseGallery ? <X size={"16"} /> : <Image size={"16"} />}
            </Button.Ripple>
          </Row>
          <Row>
            <Col lg={isCloseGallery ? "6" : "12"}>
              <Row>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup>
                    <div className={styles.DragDropModal}>
                      {TempData.image ? (
                        <img
                          className={styles.PreviewModal}
                          src={TempData.image}
                        />
                      ) : (
                        <p className={styles.DragText}>
                          Drag & Drop Image Upload
                        </p>
                      )}

                      <FormGroup className={styles.DropInputModal}>
                        {TempData.DisableMode ? null : (
                          <FileBase64
                            multiple={true}
                            onDone={(e) => GetFile(e, setTempData, TempData)}
                            key={TempData.inputkey || ""}
                          />
                        )}
                      </FormGroup>
                      {TempData.DisableMode ? null : (
                        <div style={{ position: "absolute" }}>
                          {TempData.image ? (
                            <Button.Ripple
                              onClick={() => {
                                functionThatResetsTheFileInput()
                              }}
                              className="btn-icon btn-sm"
                              color="danger"
                            >
                              <Trash size={16} />
                            </Button.Ripple>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup className="mt-2 position-relative">
                    <Label for="LocationTag">Template Name</Label>
                    {TempData.LocationTag ? (
                      <span
                        onClick={() =>
                          setTempData({ ...TempData, LocationTag: null })
                        }
                        className={styles.clearbtn1}
                      >
                        {TempData.DisableMode ? null : (
                          <FaTimes fontSize={15} />
                        )}
                      </span>
                    ) : null}
                    <DelayInput
                      value={TempData.LocationTag || ""}
                      id="LocationTag"
                      name="LocationTag"
                      placeholder="Template Name"
                      className="form-control"
                      disabled={TempData.DisableMode}
                      onChange={(e) =>
                        setTempData({
                          ...TempData,
                          LocationTag: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label for="lastNameMulti">Location or Address*</Label>
                    {TempData.address !== null ? (
                      <span
                        onClick={() => {
                          setTempData({
                            ...TempData,
                            address: null,
                            lng: null,
                            lat: null,
                            mapsLocationTag: null
                          })
                        }}
                        className={styles.clearbtn1}
                      >
                        {TempData.DisableMode ? null : (
                          <FaTimes fontSize={15} />
                        )}
                      </span>
                    ) : null}
                    {TempData.address !== null ? (
                      <Input
                        value={TempData.address}
                        id="LocationTag"
                        name="LocationTag"
                        placeholder="Template Name"
                        disabled={TempData.address !== null}
                      />
                    ) : (
                      <div style={{ position: "relative" }}>
                        <GooglePlacesAutocomplete
                          apiKey={GPAutocompleteApi}
                          locationBoxStyle={"form-control"}
                          apiOptions={{ language: "en", region: "en" }}
                          selectProps={{
                            defaultValue: TempData.address,
                            isClearable: true,
                            onChange: (val) =>
                              GetWikipediaData(val, updateStartLocation, true),
                            defaultInputValue: TempData.address
                          }}
                        />
                      </div>
                    )}
                  </FormGroup>
                  {TempData.isUpdateTag === "yes" ? (
                    <FormGroup>
                      <Label for="exampleSelect">Tag Type</Label>
                      <Select
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={WaypointTagTyps[0]?.Icon}
                              width={24}
                              style={{
                                borderRadius: "50%",
                                border: "1px solid",
                                padding: "3px"
                              }}
                            />
                            <span style={{ marginLeft: 5 }}>
                              {WaypointTagTyps[0]?.Label}
                            </span>
                          </div>
                        }
                        value={TempData?.TagType?.Enum}
                        isDisabled={TempData?.DisableMode}
                        options={WaypointTagTyps}
                        onChange={(e) =>
                          setTempData({ ...TempData, TagType: e.Enum })
                        }
                        getOptionValue={(opt) => opt.SearchTag}
                        getOptionLabel={(e) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={e.Icon}
                              width={24}
                              style={{
                                borderRadius: "50%",
                                border: "1px solid",
                                padding: "3px"
                              }}
                            />
                            <span style={{ marginLeft: 5 }}>{e.Label}</span>
                          </div>
                        )}
                      />
                    </FormGroup>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col>
              <GalleryExternalPlaces
                TempData={TempData}
                setTempData={setTempData}
                isCloseGallery={isCloseGallery}
                // handlePagination={handlePagination}
                updateStartLocation={updateStartLocation}
                // functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                checkStatus={"Template"}
                isGalleryForDialoge={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12" sm="12">
              <FormGroup style={{ position: "relative" }}>
                <Label for="Description">Description</Label>
                {TempData.description ? (
                  <span
                    onClick={() =>
                      setTempData({ ...TempData, description: null })
                    }
                    className={styles.clearbtn1}
                  >
                    {TempData.DisableMode ? null : <FaTimes fontSize={15} />}
                  </span>
                ) : null}
                <DelayInput
                  value={TempData.description || ""}
                  id="Description"
                  style={{ height: "250px" }}
                  name="Description"
                  element="textarea"
                  className="form-control"
                  placeholder="Description"
                  disabled={TempData.DisableMode}
                  onChange={(e) =>
                    setTempData({ ...TempData, description: e.target.value })
                  }
                />
              </FormGroup>
            </Col>
            <TempLanguageField
              setComStates={setComStates}
              comStates={comStates}
              setTempData={setTempData}
              TempData={TempData}
              comBStates={comBStates}
            />
          </Row>
          <Col className="d-flex justifyContent-center mt-3">
            <Col lg="6">
              <Button.Ripple
                onClick={() => {
                  ResetAllValuesToNull()
                  // setTempData({ ...TempData, Addopen: false })
                  setComStates({ ...comStates, isCloseGallery: false })
                }}
                className="w-100"
                color="primary"
                type="button"
              >
                Cancel
              </Button.Ripple>
            </Col>

            <Col lg="6">
              <Button.Ripple
                disabled={TempData.DisableMode}
                className="w-100"
                color="primary"
                type="submit"
              >
                Add
              </Button.Ripple>
            </Col>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default GeoObjectDetailDialog
