import React, { useState } from "react"
import MaterialTable from "material-table"
import {
  Edit,
  X,
  ChevronLeft,
  ChevronRight,
  ChevronsRight,
  ChevronsLeft,
  Trash,
  Grid,
  MinusCircle,
  CheckCircle,
  Share2
} from "react-feather"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  TabContent,
  TabPane,
  Row,
  Col,
  Badge,
  Spinner
} from "reactstrap"
import ReactTooltip from "react-tooltip"
import { CellStyle, HeaderStyle, RootData } from "../../common/ComStyles/tableStyleCom"
import { useSelector, useDispatch } from "react-redux"
import { MediaGalleryTabs } from "../../common/CommonTabBar/MediaGalleryTabs"
import AddMediaGalleryModal from "../../common/AddUpdateMediaGallery/AddMediaGalleryModal"
import styles from "../../../styles/Modals/modals.module.scss"
import moment from "moment"
import { WEB_DATETIME_FORMAT } from "../../../GlobalDate/Date"
import { DeleteMediaGalleryAction } from "../../../redux/actions/mediagallery"
import { DeleteMediaGalleryDailog } from "../../common/CommonModals/DeleteConModal"
import { GlobalInfoDailogObj } from "../../GlobalInfoDailog/GlobalInfoDailog"
import { IS_Media_GalleryOpen } from "../../../redux/constants/MediaGallery"
import { MediaTypeView } from "./MediaGalleryItems/MediaTypeView"
import { QRCodeLinkModalObj } from "../../common/CommonModals/CommonModals"
import { makeStyles } from "@material-ui/core"

const MediaGallery = () => {
  const [comStates, setComStates] = useState({
    handleTemplateSearch: "",
    active: "4",
    addMediaModalOpen: false,
    deleteModal: false,
    MediaData: null,
    mediaNameTag: null,
    mediaType: 4,
    languageName: "English",
    language: "en",
    MediaFile: null,
    mediaFileUrl: null,
    isAddDialog: false,
    mediaGuid: false,
    mediaFileName: null,
    addedTime: null,
    isPublic: false,
    IsDefault: false,
    detailModal: false,
    detailDialogData: {
      title: "Coming Soon",
      message: "Coming Soon"
    },
    inputkey: "",
    DataisLoaded: false
  })

  const [QRCodeStates, setQRCodeStates] = useState({
    QRCodeModal: false,
    qrValue: null,
    copied: false
  })

  const { handleTemplateSearch, active, MediaData, detailDialogData } =
    comStates
  const skin = useSelector((state) => state.auth.theme)
  const { isloading } = useSelector((state) => state?.loading)

  const dispatch = useDispatch()
  const mediaGalleryList = useSelector(
    (state) => state.mediagallery.mediagallery
  )
  const isMediaGalleryOpen = useSelector(
    (state) => state.mediagallery?.isMediaGallery
  )
  const parentGuid = useSelector((state) => state.mediagallery?.parentGuid)
  const parentMedia = useSelector((state) => state.mediagallery?.parentMedia)
  const useStyles = makeStyles((theme) => ({
    root: RootData(skin)
  }))
  const classes = useStyles()
  const headerStyle = HeaderStyle(skin)
  const cellStyle = CellStyle(skin)
  const ResetAllValuesToNull = () => {}
  const toggle = (tab) => {
    setComStates({ ...comStates, active: tab })
  }
  const DeleteMediaGallery = () => {
    dispatch(DeleteMediaGalleryAction(MediaData, parentMedia, parentGuid))
  }
  const GetTableTitle = () => {
    let title = "Audio"
    switch (active) {
      case "2":
        title = "Video"
        break
      case "3":
        title = "Document"
        break
      default:
        title = "Audio"
    }
    return title
  }
  return (
    <div>
      {/* Modals */}
      <DeleteMediaGalleryDailog
        comStates={comStates}
        setComStates={setComStates}
        title={"media"}
        DeleteMediaGallery={DeleteMediaGallery}
      />
      <AddMediaGalleryModal
        comBStates={comStates}
        setBComStates={setComStates}
        parentGuid={parentGuid}
        mediaParent={parentMedia}
      />
      <GlobalInfoDailogObj
        detailDialogData={detailDialogData}
        setComStates={setComStates}
        comStates={comStates}
      />
      <QRCodeLinkModalObj
        label={"Media"}
        setBComStates={setQRCodeStates}
        comBStates={QRCodeStates}
      />
      <Modal
        isOpen={isMediaGalleryOpen}
        toggle={() => {
          dispatch({ type: IS_Media_GalleryOpen, data: false })
          setComStates({ ...comStates, active: "4" })
        }}
        className={`modal-dialog-centered`}
        style={{ maxWidth: "75vw" }}
      >
        <ModalHeader
          toggle={() => {
            dispatch({ type: IS_Media_GalleryOpen, data: false })
            setComStates({ ...comStates, active: "4" })
          }}
        >
          Media Gallery
        </ModalHeader>
        <ModalBody className="my-2">
          <Row>
            <Col lg={"8"} className={styles.SetAtStart}>
              <MediaGalleryTabs
                toggle={toggle}
                handleTemplateSearch={handleTemplateSearch}
                active={active}
                ResetAllValuesToNull={ResetAllValuesToNull}
                parentGuid={parentGuid}
                parentMedia={parentMedia}
              />
            </Col>
            <Col lg={"4"} className={styles.SetTextEnd}>
              <Button.Ripple
                className="btn-icon"
                color="primary"
                onClick={() => {
                  setComStates({
                    ...comStates,
                    mediaNameTag: null,
                    language: "en",
                    mediaType: 4,
                    mediaFileUrl: null,
                    addMediaModalOpen: true,
                    isAddDialog: false
                  })
                }}
              >
                Add New {GetTableTitle()}
              </Button.Ripple>
            </Col>
          </Row>
          <TabContent>
            <TabPane className="mt-2" id={active}>
             <div className={classes.root}>
             <MaterialTable
                actions={[
                  {
                    icon: () => (
                      <Button.Ripple
                        className="btn-icon btn-sm"
                        data-tip={"Edit Media"}
                        color="primary"
                      >
                        <ReactTooltip />
                        <Edit size={16} />
                      </Button.Ripple>
                    ),
                    onClick: (event, rowData) => {
                      console.log(rowData)
                      setComStates({
                        ...comStates,
                        mediaNameTag: rowData.MediaNameTag,
                        language: rowData.Language,
                        mediaType: rowData.MediaType,
                        mediaFileUrl: rowData.MediaFileUrl,
                        addMediaModalOpen: true,
                        isAddDialog: true,
                        mediaGuid: rowData.MediaGuid,
                        mediaFileName: rowData.MediaFileName,
                        addedTime: rowData.AddedTime,
                        isPublic:
                          rowData?.IsPublic === null
                            ? false
                            : rowData?.IsPublic,
                        IsDefault:
                          rowData?.IsDefault === null
                            ? false
                            : rowData?.IsDefault,
                        MediaFile: null
                      })
                    }
                  },
                  {
                    icon: () => (
                      <Button.Ripple
                        className="btn-icon btn-sm"
                        data-tip={"Delete Media"}
                        color="danger"
                      >
                        {isloading ? (
                          <Spinner size={"sm"} />
                        ) : (
                          <Trash size={16} />
                        )}
                      </Button.Ripple>
                    ),
                    onClick: (event, rowData) => {
                      setComStates({
                        ...comStates,
                        deleteModal: true,
                        MediaData: rowData
                      })
                    }
                  },
                  {
                    icon: () => (
                      <Button.Ripple
                        className="btn-icon btn-sm"
                        data-tip={"Share Media"}
                        color="primary"
                      >
                        <Share2 size={16} />
                      </Button.Ripple>
                    ),
                    onClick: (event, rowData) => {
                      setQRCodeStates({
                        ...QRCodeStates,
                        QRCodeModal: true,
                        qrValue: rowData.MediaFileUrl,
                        copied: false
                      })
                    }
                  }
                ]}
                icons={{
                  ResetSearch: (props) => (
                    <X
                      size={16}
                      style={{ color: skin === "dark" ? "#d0d2d6" : "#6e6b7b" }}
                    />
                  ),
                  NextPage: (props) => (
                    <ChevronRight
                      size={16}
                      style={{ color: skin === "dark" ? "#d0d2d6" : "#6e6b7b" }}
                    />
                  ),
                  PreviousPage: (props) => (
                    <ChevronLeft
                      size={16}
                      style={{ color: skin === "dark" ? "#d0d2d6" : "#6e6b7b" }}
                    />
                  ),
                  LastPage: (props) => (
                    <ChevronsRight
                      size={16}
                      style={{ color: skin === "dark" ? "#d0d2d6" : "#6e6b7b" }}
                    />
                  ),
                  FirstPage: (props) => (
                    <ChevronsLeft
                      size={16}
                      style={{ color: skin === "dark" ? "#d0d2d6" : "#6e6b7b" }}
                    />
                  )
                }}
                options={{
                  headerStyle,
                  actionsColumnIndex: -1,
                  search: true,
                  sorting: true,
                  pageSize: 5,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [5, 10, 25, 50, 100],
                  searchFieldStyle: {
                    color: skin === "dark" && "#d0d2d6"
                  }
                }}
                style={{ backgroundColor: skin === "dark" && "#283046" }}
                title={`${GetTableTitle()} List`}
                columns={[
                  {
                    title: "Preview",
                    field: "MediaFileUrl",
                    headerStyle,
                    cellStyle,
                    render: (rowData) => {
                      return MediaTypeView(
                        Number(rowData.MediaType),
                        rowData.MediaFileUrl,
                        true
                      )
                    }
                  },

                  {
                    title: "media Name",
                    field: "MediaNameTag",
                    cellStyle,
                    headerStyle,
                    render: (rowData) => {
                      return (
                        <Badge data-tip={rowData.MediaNameTag} color="dark">
                          {rowData.MediaNameTag.substring(0, 30)}
                          {rowData.MediaNameTag.length > 30 ? "..." : null}
                        </Badge>
                      )
                    }
                  },

                  {
                    title: "Language",
                    field: "Language",
                    cellStyle,
                    headerStyle,
                    render: (rowData) => {
                      return (
                        <Badge color="success" style={{ marginLeft: "28px" }}>
                          {rowData.Language}
                        </Badge>
                      )
                    }
                  },
                  {
                    title: "Public?",
                    field: "IsPublic",
                    cellStyle,
                    headerStyle,
                    render: (rowData) =>
                      rowData.IsPublic ? (
                        <CheckCircle style={{ marginLeft: "28px" }} />
                      ) : (
                        <MinusCircle style={{ marginLeft: "28px" }} />
                      )
                  },
                  {
                    title: "Default?",
                    field: "IsDefault",
                    cellStyle,
                    headerStyle,
                    render: (rowData) =>
                      rowData.IsDefault ? (
                        <CheckCircle style={{ marginLeft: "28px" }} />
                      ) : (
                        <MinusCircle style={{ marginLeft: "28px" }} />
                      )
                  },
                  {
                    title: "Time Modified",
                    field: "AddedTime",
                    cellStyle,
                    headerStyle,
                    render: (rowData) => {
                      let status = "Waiting for time"
                      if (rowData.AddedTime !== null) {
                        status = moment(rowData.AddedTime).format(
                          WEB_DATETIME_FORMAT
                        )
                      }
                      return status
                    }
                  }
                ]}
                // onSearchChange={handleTemplateSearch}
                data={mediaGalleryList}
              />
             </div>
            </TabPane>
          </TabContent>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default MediaGallery
