import {
  Card,
  CardImg,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Input,
  Form,
  Button,
  Label,
  Media,
  Badge
} from "reactstrap"
import React, { useEffect, useState } from "react"
import styles from "../../../styles/createtrip.module.scss"
import ModalStyle from "../../../styles/Modals/modals.module.scss"
import FileBase64 from "react-file-base64"
import Flatpickr from "react-flatpickr"
import * as Icons from "react-feather"
import { FaTimes } from "react-icons/fa"
import GooglePlacesAutocomplete from "react-google-places-autocomplete"
import QRCode from "qrcode.react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import {
  GlobalInfoTextColor,
  WAYPOINT_REGULAR_ICON
} from "../../../GlobalAssets/AssetsList"
import Select from "react-select"
import moment from "moment"
import { countf, countTagType } from "../commonFunc"
import { OpenCloseGallery } from "../CommFunctions/OpenCloseGallery"
import { WEB_DATETIME_FORMAT } from "../../../GlobalDate/Date"
import DelayInput from "react-delay-input"
import { WaypointTagTyps } from "../../../enums/WayPointTagTypes"
import { GetWikipediaData } from "../../Items/common/GetWikipediaData"
import GalleryExternalPlaces from "../../Items/common/GalleryExternalPlaces"
import TempLanguageField from "../commonFields/TempLanguageField"
import { useDispatch, useSelector } from "react-redux"
import { Set_MEDIA_GALLERY_VALUES } from "../CommFunctions/MediaGalleryFuncs"
import { GPAutocompleteApi } from "../../../utility/ApiKeys/ApiKey"
import ApiButtonTitle from "../../Items/common/ApiButton/ApiButtonTitle"

export function ModalDeleteWay({
  onRemove,
  wayDeleteModal,
  deleteOrderId,
  setWayDeleteModal
}) {
  return (
    <Modal
      isOpen={wayDeleteModal}
      toggle={() => setWayDeleteModal(false)}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader toggle={() => setWayDeleteModal(false)}>
        Delete Waypoint
      </ModalHeader>
      <ModalBody>Are you sure to want to delete waypoint?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => setWayDeleteModal(false)}>
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={() => {
            onRemove(deleteOrderId)
            setWayDeleteModal(false)
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export function ModalDeleteWayObj({ onRemove, setComStates, comStates }) {
  return (
    <Modal
      isOpen={comStates.wayDeleteModal}
      toggle={() => setComStates({ ...comStates, wayDeleteModal: false })}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => setComStates({ ...comStates, wayDeleteModal: false })}
      >
        Delete Waypoint
      </ModalHeader>
      <ModalBody>Are you sure to want to delete waypoint?</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => setComStates({ ...comStates, wayDeleteModal: false })}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={() => {
            onRemove(comStates.deleteOrderId)
            setComStates({ ...comStates, wayDeleteModal: false })
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

{
  /* Detail Modal */
}
export function DetailModal({ detailModal, setDetailModal }) {
  return (
    <Modal
      isOpen={detailModal}
      toggle={() => setDetailModal(false)}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader toggle={() => setDetailModal(false)}>
        Detail Modal
      </ModalHeader>
      <ModalBody>
        <Form>detail...</Form>
      </ModalBody>
    </Modal>
  )
}

{
  /* Add Edit End Modal */
}
export function AddEditEndModal({
  addEditEndtWayPoint,
  onChangeEtime,
  EndWaygetFiles,
  updateEndLocation,
  functionThatResetsTheFileInput,
  SetLocationValues,
  locationValues,
  comStates,
  setComStates,
  ETime
}) {
  const { eplace } = locationValues
  const { description, LocationTag, inputkey, addEndImage, addEditEndModal } =
    comStates
  const dispatch = useDispatch()
  const isCloseGallery = useSelector((state) => state?.common?.isCloseGallery)
  return (
    <Modal
      isOpen={addEditEndModal}
      toggle={() => setComStates({ ...comStates, addEditEndModal: false })}
      className={`modal-dialog-centered ${ModalStyle.WideModal}`}
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => setComStates({ ...comStates, addEditEndModal: false })}
      >
        Add/Edit End Waypoint
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row style={{ justifyContent: "end" }}>
            <Button.Ripple
              data-tip={
                !isCloseGallery
                  ? "Open Images & Places"
                  : "Close Images & Places"
              }
              className={`btn-icon btn-sm`}
              color={`${isCloseGallery ? "danger" : "primary"}`}
              onClick={() => {
                OpenCloseGallery(dispatch, isCloseGallery)
              }}
            >
              {isCloseGallery ? (
                <Icons.X size={"16"} />
              ) : (
                <Icons.Image size={"16"} />
              )}
            </Button.Ripple>
          </Row>
          <Row>
            <Col lg={isCloseGallery ? "6" : "12"}>
              <Row>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup>
                    <div className={styles.DragDrop}>
                      {addEndImage ? (
                        <img
                          className={styles.Preview}
                          src={addEndImage}
                          width={150}
                          height={150}
                        />
                      ) : (
                        <p className={styles.DragText}>
                          Drag & Drop Image Upload
                        </p>
                      )}

                      <FormGroup className={styles.DropInput}>
                        <FileBase64
                          multiple={true}
                          onDone={(e) => EndWaygetFiles(e)}
                          key={inputkey || ""}
                        />
                      </FormGroup>
                      <div style={{ position: "absolute" }}>
                        {addEndImage ? (
                          <Button.Ripple
                            onClick={() => {
                              functionThatResetsTheFileInput()
                            }}
                            className="btn-icon btn-sm"
                            color="danger"
                          >
                            <Icons.Trash size={16} />
                          </Button.Ripple>
                        ) : null}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup className="position-relative">
                    <Label htmlFor="lastNameMulti">Location or Address*</Label>
                    {eplace !== null ? (
                      <span
                        onClick={() => {
                          SetLocationValues({
                            ...locationValues,
                            ELat: null,
                            ELng: null,
                            ETag: null,
                            eplace: null
                          })
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    {eplace !== null ? (
                      <Input
                        value={eplace}
                        id="LocationTag"
                        name="LocationTag"
                        placeholder="Template Name"
                        disabled={eplace !== null}
                      />
                    ) : (
                      <div style={{ position: "relative" }}>
                        <GooglePlacesAutocomplete
                          apiKey={GPAutocompleteApi}
                          locationBoxStyle={"form-control"}
                          selectProps={{
                            defaultInputValue: eplace,
                            isClearable: true,
                            onChange: (val) => {
                              GetWikipediaData(val, updateEndLocation, false)
                            }
                          }}
                        />
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label htmlFor="CountryMulti">Waypoint Name</Label>
                    {LocationTag !== null ? (
                      <span
                        onClick={() => {
                          setComStates({
                            ...comStates,
                            LocationTag: null
                          })
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    <Input
                      type="text"
                      value={LocationTag || ""}
                      placeholder="Custom waypoint name"
                      onChange={(e) =>
                        setComStates({
                          ...comStates,
                          LocationTag: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <Label htmlFor="CountryMulti">Time</Label>
                    {ETime !== null ? (
                      <span
                        onClick={() => {
                          onChangeEtime(null)
                        }}
                        style={{ marginTop: "2px" }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    <Flatpickr
                      data-enable-time
                      id="date-time-picker"
                      className="form-control"
                      value={ETime}
                      style={{ backgroundColor: "transparent" }}
                      onChange={(e) => onChangeEtime(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={"6"}>
              <GalleryExternalPlaces
                TempData={comStates}
                setTempData={setComStates}
                updateStartLocation={updateEndLocation}
                functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                checkStatus={"CreateTripEndW"}
                isGalleryForDialoge={true}
              />
            </Col>
          </Row>

          <Row>
            <Col lg="12" md="12" sm="12">
              <FormGroup className="position-relative">
                <Label htmlFor="CountryMulti">Description</Label>
                {description !== null ? (
                  <span
                    onClick={() => {
                      setComStates({
                        ...comStates,
                        description: null
                      })
                    }}
                    className={styles.clearbtn1}
                  >
                    <FaTimes fontSize={15} />
                  </span>
                ) : null}
                <DelayInput
                  className="form-control"
                  element="textarea"
                  value={description || ""}
                  style={{ height: "150px" }}
                  placeholder="Description"
                  onChange={(e) =>
                    setComStates({
                      ...comStates,
                      description: e.target.value
                    })
                  }
                />
              </FormGroup>
            </Col>
            <TempLanguageField
              setTempData={setComStates}
              TempData={comStates}
            />
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Col lg="6" md="6" sm="6">
            <Button
              color="primary"
              className="w-100"
              onClick={() =>
                setComStates({ ...comStates, addEditEndModal: false })
              }
            >
              cancel
            </Button>
          </Col>
          <Col lg="6" md="6" sm="6">
            <Button
              color="primary"
              className="w-100"
              onClick={() => addEditEndtWayPoint()}
            >
              Add
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

{
  /* Add Edit Start Modal */
}
export function AddEditStartModal({
  onChangeStime,
  addEditStartWayPoint,
  functionThatResetsTheFileInput,
  StartWaygetFiles,
  updateStartLocation,
  SetLocationValues,
  locationValues,
  setComStates,
  comStates,
  STime
}) {
  const { splace } = locationValues
  const {
    description,
    inputkey,
    LocationTag,
    addStartImage,
    addEditStartModal
  } = comStates
  const dispatch = useDispatch()
  const isCloseGallery = useSelector((state) => state?.common?.isCloseGallery)

  return (
    <Modal
      isOpen={addEditStartModal}
      toggle={() => setComStates({ ...comStates, addEditStartModal: false })}
      className={`modal-dialog-centered ${ModalStyle.WideModal}`}
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => setComStates({ ...comStates, addEditStartModal: false })}
      >
        Add/Edit Start Waypoint
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row style={{ justifyContent: "end" }}>
            <Button.Ripple
              data-tip={
                !isCloseGallery
                  ? "Open Images & Places"
                  : "Close Images & Places"
              }
              className={`btn-icon btn-sm`}
              color={`${isCloseGallery ? "danger" : "primary"}`}
              onClick={() => {
                OpenCloseGallery(dispatch, isCloseGallery)
              }}
            >
              {isCloseGallery ? (
                <Icons.X size={"16"} />
              ) : (
                <Icons.Image size={"16"} />
              )}
            </Button.Ripple>
          </Row>
          <Row>
            <Col lg={isCloseGallery ? "6" : "12"}>
              <Row>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup>
                    <div className={styles.DragDrop}>
                      {addStartImage ? (
                        <img
                          className={styles.Preview}
                          src={addStartImage}
                          width={150}
                          height={150}
                        />
                      ) : (
                        <p className={styles.DragText}>
                          Drag & Drop Image Upload
                        </p>
                      )}

                      <FormGroup className={styles.DropInput}>
                        <FileBase64
                          multiple={true}
                          onDone={(e) => StartWaygetFiles(e)}
                          key={inputkey || ""}
                        />
                      </FormGroup>
                      <div style={{ position: "absolute" }}>
                        {addStartImage ? (
                          <Button.Ripple
                            onClick={() => {
                              functionThatResetsTheFileInput()
                            }}
                            className="btn-icon btn-sm"
                            color="danger"
                          >
                            <Icons.Trash size={16} />
                          </Button.Ripple>
                        ) : null}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup className="position-relative">
                    <Label htmlFor="lastNameMulti">Location or Address*</Label>
                    {splace !== null ? (
                      <span
                        onClick={() => {
                          SetLocationValues({
                            ...locationValues,
                            SLat: null,
                            SLng: null,
                            splace: null
                          })
                          setComStates({
                            ...comStates,
                            LocationTag: null
                          })
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    {splace !== null ? (
                      <Input
                        value={splace}
                        id="LocationTag"
                        name="LocationTag"
                        placeholder="Template Name"
                        disabled={splace !== null}
                      />
                    ) : (
                      <div style={{ position: "relative" }}>
                        <GooglePlacesAutocomplete
                          apiKey={GPAutocompleteApi}
                          locationBoxStyle={"form-control"}
                          selectProps={{
                            defaultInputValue: splace,
                            isClearable: true,
                            onChange: (val) => {
                              GetWikipediaData(val, updateStartLocation, false)
                            }
                          }}
                        />
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label htmlFor="CountryMulti">Waypoint Name</Label>
                    {LocationTag !== null ? (
                      <span
                        onClick={() => {
                          setComStates({
                            ...comStates,
                            LocationTag: null
                          })
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    <DelayInput
                      type="text"
                      className="form-control"
                      value={LocationTag || ""}
                      placeholder="Custom waypoint name"
                      onChange={(e) =>
                        setComStates({
                          ...comStates,
                          LocationTag: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <Label htmlFor="CountryMulti">Time</Label>
                    {STime !== null ? (
                      <span
                        onClick={() => {
                          onChangeStime(null)
                        }}
                        style={{ marginTop: "2px" }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    <Flatpickr
                      data-enable-time
                      id="date-time-picker"
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={STime}
                      onChange={(e) => onChangeStime(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={"6"}>
              <GalleryExternalPlaces
                TempData={comStates}
                setTempData={setComStates}
                updateStartLocation={updateStartLocation}
                functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                checkStatus={"CreateTripStartW"}
                isGalleryForDialoge={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <FormGroup className="position-relative">
                <Label htmlFor="CountryMulti">Description</Label>
                {description !== null ? (
                  <span
                    onClick={() => {
                      setComStates({
                        ...comStates,
                        description: null
                      })
                    }}
                    className={styles.clearbtn1}
                  >
                    <FaTimes fontSize={15} />
                  </span>
                ) : null}
                <DelayInput
                  className="form-control"
                  element="textarea"
                  value={description || ""}
                  style={{ height: "150px" }}
                  placeholder="Description"
                  onChange={(e) =>
                    setComStates({
                      ...comStates,
                      description: e.target.value
                    })
                  }
                />
              </FormGroup>
            </Col>
            <TempLanguageField
              setTempData={setComStates}
              TempData={comStates}
            />
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Col lg="6" md="6" sm="6">
            <Button
              color="primary"
              className="w-100"
              onClick={() =>
                setComStates({ ...comStates, addEditStartModal: false })
              }
            >
              cancel
            </Button>
          </Col>
          <Col lg="6" md="6" sm="6">
            <Button
              color="primary"
              className="w-100"
              onClick={() => addEditStartWayPoint()}
            >
              Add
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}

{
  /* Upate Waypoint Modal */
}

export function UpdateWayPointModal({
  onUpdateWay,
  getFilesWayUpdate,
  functionThatResetsTheFileInput,
  wayupdateLocation,
  UpdateWayPoint,
  setTONullLocation,
  PlaceHolder,
  handleChangeTagTyp,
  onUpdateTag,
  waypointStates,
  setWayPointStates,
  tripStates,
  TagStates,
  sPlaceStates
}) {
  const {
    wayeditModal,
    activeWayApi,
    TopTitle,
    waypTargetTime,
    description,
    waypOrderId,
    LocationTag,
    waypimage,
    Guid
  } = waypointStates
  const { inputkey } = tripStates
  const { tagField } = TagStates
  const { splace } = sPlaceStates
  const dispatch = useDispatch()
  const isCloseGallery = useSelector((state) => state?.common?.isCloseGallery)
  const { isloading } = useSelector((state) => state?.loading)

  const GetMediaGallery = (Guid, parentMedia, mediaType) => {
    Set_MEDIA_GALLERY_VALUES(dispatch, Guid, parentMedia, mediaType)
  }
  return (
    <div>
      <Modal
        isOpen={wayeditModal}
        toggle={() => {
          setWayPointStates({
            ...waypointStates,
            wayeditModal: false,
            activeWayApi: false
          })
        }}
        className={`modal-dialog-centered ${ModalStyle.WideModal}`}
        modalClassName="danger"
      >
        <ModalHeader
          toggle={() => {
            setWayPointStates({
              ...waypointStates,
              wayeditModal: false,
              activeWayApi: false
            })
          }}
        >
          Edit {TopTitle}
        </ModalHeader>
        <ModalBody>
          <Form>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button.Ripple
                className={`btn-icon btn-sm mr-1`}
                data-tip={"Media Gallery"}
                color="success"
                onClick={() => GetMediaGallery(Guid, 3, 4)}
              >
                <Icons.Grid size={16} />
              </Button.Ripple>
              <Button.Ripple
                data-tip={
                  !isCloseGallery
                    ? "Open Images & Places"
                    : "Close Images & Places"
                }
                className={`btn-icon btn-sm`}
                color={`${isCloseGallery ? "danger" : "primary"}`}
                onClick={() => {
                  OpenCloseGallery(dispatch, isCloseGallery)
                }}
              >
                {isCloseGallery ? (
                  <Icons.X size={"16"} />
                ) : (
                  <Icons.Image size={"16"} />
                )}
              </Button.Ripple>
            </div>
            <Row>
              {tagField ? null : (
                <div className="mt-0 mb-1 text-center w-100">
                  <h4>{countf(waypOrderId)}</h4>
                </div>
              )}
            </Row>
            <Row>
              <Col lg={isCloseGallery ? "6" : "12"}>
                <Row>
                  <Col lg={isCloseGallery ? "12" : "6"}>
                    <FormGroup>
                      <div className={styles.DragDrop}>
                        {waypimage ? (
                          <img
                            className={styles.Preview}
                            src={waypimage}
                            width={150}
                            height={150}
                          />
                        ) : (
                          <p className={styles.DragText}>
                            Drag & Drop Image Upload
                          </p>
                        )}

                        <FormGroup className={styles.DropInput}>
                          <FileBase64
                            multiple={true}
                            onDone={(e) => getFilesWayUpdate(e)}
                            key={inputkey || ""}
                          />
                        </FormGroup>
                        <div style={{ position: "absolute" }}>
                          {waypimage ? (
                            <Button.Ripple
                              onClick={() => {
                                functionThatResetsTheFileInput()
                                setWayPointStates({
                                  ...waypointStates,
                                  waypimage: null,
                                  waypfile: null,
                                  regular: null,
                                  thumb: null
                                })
                                // setWayFile(null)
                              }}
                              className="btn-icon btn-sm"
                              color="danger"
                            >
                              <Icons.Trash size={16} />
                            </Button.Ripple>
                          ) : null}
                        </div>
                      </div>
                    </FormGroup>
                  </Col>
                  <Col lg={isCloseGallery ? "12" : "6"}>
                    <FormGroup className="position-relative">
                      <Label htmlFor="lastNameMulti">
                        Location or Address*
                      </Label>
                      {splace !== null ? (
                        <span
                          onClick={() => {
                            setTONullLocation()
                          }}
                          className={styles.clearbtn1}
                        >
                          <FaTimes fontSize={15} />
                        </span>
                      ) : null}
                      {splace !== null ? (
                        <Input
                          value={splace || ""}
                          id="LocationTag"
                          name="LocationTag"
                          placeholder="Template Name"
                          disabled={splace !== null}
                        />
                      ) : (
                        <div style={{ position: "relative" }}>
                          <GooglePlacesAutocomplete
                            apiKey={GPAutocompleteApi}
                            locationBoxStyle={"form-control"}
                            selectProps={{
                              defaultInputValue: splace,
                              isClearable: true,
                              onChange: (val) => {
                                GetWikipediaData(val, wayupdateLocation, false)
                              }
                            }}
                          />
                        </div>
                      )}
                    </FormGroup>

                    {tagField ? (
                      <FormGroup>
                        <Label for="exampleSelect">Tag Type</Label>
                        <Select
                          placeholder={
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={PlaceHolder.Icon}
                                width={24}
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid",
                                  padding: "3px"
                                }}
                              />
                              <span style={{ marginLeft: 5 }}>
                                {PlaceHolder.Label}
                              </span>
                            </div>
                          }
                          // value={tagType.Enum}
                          options={WaypointTagTyps}
                          getOptionValue={(opt) => opt.SearchTag}
                          onChange={handleChangeTagTyp}
                          isSearchable={true}
                          getOptionLabel={(e) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <img
                                src={e.Icon}
                                width={24}
                                style={{
                                  borderRadius: "50%",
                                  border: "1px solid",
                                  padding: "3px"
                                }}
                              />
                              <span style={{ marginLeft: 5 }}>{e.Label}</span>
                            </div>
                          )}
                        />
                      </FormGroup>
                    ) : null}
                    <FormGroup className="position-relative">
                      <Label htmlFor="CountryMulti">
                        {tagField ? "Tag Name" : "Waypoint Name"}{" "}
                      </Label>
                      {LocationTag !== null ? (
                        <span
                          onClick={() => {
                            setWayPointStates({
                              ...waypointStates,
                              LocationTag: null
                            })
                          }}
                          className={styles.clearbtn1}
                        >
                          <FaTimes fontSize={15} />
                        </span>
                      ) : null}
                      <DelayInput
                        className="form-control"
                        type="text"
                        value={LocationTag || ""}
                        placeholder="Custom waypoint name"
                        onChange={(e) =>
                          setWayPointStates({
                            ...waypointStates,
                            LocationTag: e.target.value
                          })
                        }
                      />
                    </FormGroup>
                    {tagField ? null : (
                      <FormGroup className="position-relative">
                        <Label htmlFor="CountryMulti">Time</Label>
                        {waypTargetTime !== null ? (
                          <span
                            onClick={() => {
                              setWayPointStates({
                                ...waypointStates,
                                waypTargetTime: null
                              })
                            }}
                            className={styles.clearbtn1}
                          >
                            <FaTimes fontSize={15} />
                          </span>
                        ) : null}
                        <Flatpickr
                          data-enable-time
                          id="date-time-picker"
                          className="form-control"
                          style={{ backgroundColor: "transparent" }}
                          value={waypTargetTime}
                          onChange={(e) =>
                            setWayPointStates({
                              ...waypointStates,
                              waypTargetTime: e
                            })
                          }
                        />
                      </FormGroup>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col lg={"6"}>
                <GalleryExternalPlaces
                  waypointStates={waypointStates}
                  setWayPointStates={setWayPointStates}
                  updateStartLocation={wayupdateLocation}
                  functionThatResetsTheFileInput={
                    functionThatResetsTheFileInput
                  }
                  checkStatus={"UpdateWaypoint"}
                  isGalleryForDialoge={true}
                />
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12">
                <FormGroup className="position-relative">
                  <Label htmlFor="CountryMulti">Description</Label>
                  {description !== null ? (
                    <span
                      onClick={() => {
                        setWayPointStates({
                          ...waypointStates,
                          description: null
                        })
                      }}
                      className={styles.clearbtn1}
                    >
                      <FaTimes fontSize={15} />
                    </span>
                  ) : null}
                  <Input
                    type="textarea"
                    value={description || ""}
                    style={{ height: "150px" }}
                    placeholder="Description"
                    onChange={(e) =>
                      setWayPointStates({
                        ...waypointStates,
                        description: e.target.value
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <TempLanguageField
                setTempData={setWayPointStates}
                TempData={waypointStates}
              />
            </Row>
            <Row className="mt-3">
              <Col lg="6" md="6">
                <Button
                  color="primary"
                  className="w-100"
                  onClick={() => {
                    setWayPointStates({
                      ...waypointStates,
                      wayeditModal: false
                    })
                    OpenCloseGallery(dispatch, isCloseGallery)
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col lg="6" md="6">
                {tagField ? (
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={() => {
                      if (activeWayApi) {
                        UpdateWayPoint()
                      } else {
                        onUpdateTag()
                      }
                    }}
                    disabled={isloading}
                  >
                    <ApiButtonTitle title={"Update"} />
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={() => {
                      if (activeWayApi) {
                        UpdateWayPoint()
                      } else {
                        onUpdateWay()
                      }
                    }}
                    disabled={isloading}
                  >
                    <ApiButtonTitle title={"Update"} />
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export function UpdateWayPointModalObj({
  onUpdateWay,
  getFilesWay,
  functionThatResetsTheFileInput,
  wayupdateLocation,
  tagField,
  setTONullLocation,
  TopTitle,
  OrderId,
  comStates,
  setComStates,
  onUpdateTag,
  setWayTime,
  wayTime
}) {
  const {
    wayimage,
    activeWayApi,
    updateModal,
    inputkey,
    description,
    LocationTag,
    wayaddress
  } = comStates
  const dispatch = useDispatch()
  const isCloseGallery = useSelector((state) => state?.common?.isCloseGallery)

  return (
    <Modal
      isOpen={updateModal}
      toggle={() => {
        setComStates({
          ...comStates,
          updateModal: false,
          activeWayApi: false,
          gallerySearch: ""
        })
        // OpenCloseGallery(dispatch, isCloseGallery)
      }}
      className={`modal-dialog-centered ${ModalStyle.WideModal}`}
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => {
          setComStates({
            ...comStates,
            updateModal: false,
            activeWayApi: false,
            gallerySearch: ""
          })
          // OpenCloseGallery(dispatch, isCloseGallery)
        }}
      >
        Edit {TopTitle}
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row className={styles.gClosebtnJust}>
            <Button.Ripple
              data-tip={
                !isCloseGallery
                  ? "Open Images & Places"
                  : "Close Images & Places"
              }
              className={`btn-icon btn-sm`}
              color={`${isCloseGallery ? "danger" : "primary"}`}
              onClick={() => {
                OpenCloseGallery(dispatch, isCloseGallery)
              }}
            >
              {isCloseGallery ? (
                <Icons.X size={"16"} />
              ) : (
                <Icons.Image size={"16"} />
              )}
            </Button.Ripple>
          </Row>
          <Row>
            {tagField ? null : (
              <div className="mt-0 mb-1 text-center w-100">
                <h4>{countf(OrderId)}</h4>
              </div>
            )}

            <Col lg={isCloseGallery ? "6" : "12"}>
              <Row>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup>
                    <div className={styles.DragDrop}>
                      {wayimage ? (
                        <img
                          className={styles.Preview}
                          src={wayimage}
                          width={150}
                          height={150}
                        />
                      ) : (
                        <p className={styles.DragText}>
                          Drag & Drop Image Upload
                        </p>
                      )}

                      <FormGroup className={styles.DropInput}>
                        <FileBase64
                          multiple={true}
                          onDone={(e) => getFilesWay(e)}
                          key={inputkey || ""}
                        />
                      </FormGroup>
                      <div style={{ position: "absolute" }}>
                        {wayimage ? (
                          <Button.Ripple
                            onClick={() => {
                              functionThatResetsTheFileInput()
                            }}
                            className="btn-icon btn-sm"
                            color="danger"
                          >
                            <Icons.Trash size={16} />
                          </Button.Ripple>
                        ) : null}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup className="position-relative">
                    <Label htmlFor="lastNameMulti">Location or Address*</Label>
                    {wayaddress !== null ? (
                      <span
                        onClick={() => {
                          setTONullLocation()
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    {wayaddress !== null ? (
                      <Input
                        value={wayaddress || ""}
                        id="LocationTag"
                        name="LocationTag"
                        placeholder="Template Name"
                        disabled={wayaddress !== null}
                      />
                    ) : (
                      <div style={{ position: "relative" }}>
                        <GooglePlacesAutocomplete
                          apiKey={GPAutocompleteApi}
                          locationBoxStyle={"form-control"}
                          selectProps={{
                            defaultInputValue: wayaddress,
                            isClearable: true,
                            onChange: (val) => {
                              GetWikipediaData(val, wayupdateLocation, false)
                            }
                          }}
                        />
                      </div>
                    )}
                  </FormGroup>

                  {tagField ? (
                    <FormGroup>
                      <Label for="exampleSelect">Tag Type</Label>
                      <Select
                        placeholder={
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={PlaceHolder.Icon}
                              width={24}
                              style={{
                                borderRadius: "50%",
                                border: "1px solid",
                                padding: "3px"
                              }}
                            />
                            <span style={{ marginLeft: 5 }}>
                              {PlaceHolder.Label}
                            </span>
                          </div>
                        }
                        // value={tagType.Enum}
                        options={WaypointTagTyps}
                        getOptionValue={(opt) => opt.SearchTag}
                        onChange={handleChangeTagTyp}
                        isSearchable={true}
                        getOptionLabel={(e) => (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={e.Icon}
                              width={24}
                              style={{
                                borderRadius: "50%",
                                border: "1px solid",
                                padding: "3px"
                              }}
                            />
                            <span style={{ marginLeft: 5 }}>{e.Label}</span>
                          </div>
                        )}
                      />
                    </FormGroup>
                  ) : null}
                  <FormGroup className="position-relative">
                    <Label htmlFor="CountryMulti">
                      {tagField ? "Tag Name" : "Waypoint Name"}{" "}
                    </Label>
                    {LocationTag !== null ? (
                      <span
                        onClick={() => {
                          setComStates({
                            ...comStates,
                            LocationTag: null
                          })
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    <DelayInput
                      className="form-control"
                      type="text"
                      value={LocationTag || ""}
                      placeholder="Custom waypoint name"
                      onChange={(e) =>
                        setComStates({
                          ...comStates,
                          LocationTag: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                  {tagField ? null : (
                    <FormGroup className="position-relative">
                      <Label htmlFor="CountryMulti">Time</Label>
                      {wayTime !== null ? (
                        <span
                          onClick={() => {
                            setWayTime(null)
                          }}
                          className={styles.clearbtn1}
                        >
                          <FaTimes fontSize={15} />
                        </span>
                      ) : null}
                      <Flatpickr
                        data-enable-time
                        id="date-time-picker"
                        className="form-control"
                        style={{ backgroundColor: "transparent" }}
                        value={wayTime}
                        onChange={(e) => setWayTime(e)}
                      />
                    </FormGroup>
                  )}
                </Col>
              </Row>
            </Col>

            <Col lg={"6"}>
              <GalleryExternalPlaces
                waypointStates={comStates}
                setWayPointStates={setComStates}
                TempData={comStates}
                setTempData={setComStates}
                updateStartLocation={wayupdateLocation}
                functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                checkStatus={"Waypoint"}
                isGalleryForDialoge={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <FormGroup className="position-relative">
                <Label htmlFor="CountryMulti">Description</Label>
                {description !== null ? (
                  <span
                    onClick={() => {
                      setComStates({
                        ...comStates,
                        description: null
                      })
                    }}
                    className={styles.clearbtn1}
                  >
                    <FaTimes fontSize={15} />
                  </span>
                ) : null}
                <Input
                  type="textarea"
                  value={description || ""}
                  style={{ height: "150px" }}
                  placeholder="Description"
                  onChange={(e) =>
                    setComStates({
                      ...comStates,
                      description: e.target.value
                    })
                  }
                />
              </FormGroup>
            </Col>
            <TempLanguageField
              setTempData={setComStates}
              TempData={comStates}
            />
          </Row>
          <Col className="d-flex justifyContent-center mt-3">
            <Col lg="6">
              <FormGroup className="">
                <Button
                  color="primary"
                  className="w-100"
                  onClick={() => {
                    setComStates({
                      ...comStates,
                      updateModal: false,
                      gallerySearch: ""
                    })
                    // OpenCloseGallery(dispatch, isCloseGallery)
                    // setTONull()
                  }}
                >
                  Cancel
                </Button>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup className="">
                {tagField ? (
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={() => {
                      if (activeWayApi) {
                        UpdateWayPoint()
                      } else {
                        onUpdateTag()
                      }
                    }}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    className="w-100"
                    onClick={() => {
                      if (activeWayApi) {
                        UpdateWayPoint()
                      } else {
                        onUpdateWay()
                      }
                    }}
                  >
                    Update
                  </Button>
                )}
              </FormGroup>
            </Col>
          </Col>
        </Form>
      </ModalBody>
    </Modal>
  )
}
// WayPoint Detail Modal

export const WayPointDetailModal = ({
  editModal,
  waypAddress,
  description,
  waypTargetTime,
  waypOrderId,
  waypName,
  tagField,
  tagType,
  editTask,
  setUpdateValues,
  waypointStates,
  setWayPointStates
}) => {
  const { waypIcon, activeWayApi, Guid } = waypointStates
  const dispatch = useDispatch()
  const GetMediaGallery = (Guid, parentMedia, mediaType) => {
    Set_MEDIA_GALLERY_VALUES(dispatch, Guid, parentMedia, mediaType)
  }
  return (
    <div>
      <Modal
        isOpen={editModal}
        toggle={() =>
          setWayPointStates({ ...waypointStates, wayDetailModal: false })
        }
        className={`modal-dialog-centered ${ModalStyle.WideModal}`}
      >
        <ModalHeader
          toggle={() =>
            setWayPointStates({ ...waypointStates, wayDetailModal: false })
          }
        >
          {waypName}
        </ModalHeader>
        <ModalBody>
          <p style={{ display: "flex", justifyContent: "end" }}>
            <Button.Ripple
              className={`btn-icon btn-sm mr-1`}
              data-tip={"Media Gallery"}
              color="success"
              onClick={() => GetMediaGallery(Guid, 3, 4)}
            >
              <Icons.Grid size={16} />
            </Button.Ripple>
            <Button.Ripple
              className="btn-icon btn-sm"
              color="primary"
              onClick={() => {
                setUpdateValues(editTask, "Waypoint", activeWayApi)
                // setWayPointStates({...waypointStates, wayeditModal:true, wayDetailModal:false})
              }}
            >
              <Icons.Edit size={16} />
            </Button.Ripple>
          </p>
          <Row>
            <Col lg={"6"}>
              <CardImg
                src={waypIcon ? waypIcon : WAYPOINT_REGULAR_ICON}
                style={{
                  width: "100%",
                  aspectRatio: "4/3",
                  objectFit: "cover"
                }}
                className="img-fluid mt-2"
              />
            </Col>
            <Col lg={"6"} className="mt-1">
              <Media className="mt-1">
                <small
                  style={{ fontWeight: "bolder" }}
                  className={`${GlobalInfoTextColor} mr-25`}
                  onClick={(e) => e.preventDefault()}
                >
                  Name <Icons.Home size={12} /> :
                </small>
                {waypName}
              </Media>
              <Media className="mt-1">
                <Media body>
                  <small
                    style={{ fontWeight: "bolder" }}
                    className={`${GlobalInfoTextColor} mr-25`}
                    onClick={(e) => e.preventDefault()}
                  >
                    Location <Icons.MapPin size={12} /> :
                  </small>
                  {waypAddress}{" "}
                  {tagField ? null : <small>{countf(waypOrderId)}</small>}
                </Media>
              </Media>

              {tagField ? (
                <div className="mt-1">
                  <small
                    style={{ fontWeight: "bolder" }}
                    className={`${GlobalInfoTextColor} mr-25`}
                  >
                    Tag Type <Icons.Flag size={12} /> :
                  </small>{" "}
                  <small className="text-body mr-25">
                    {countTagType(tagType)}
                  </small>
                </div>
              ) : null}
              {tagField ? null : (
                <div className="mt-1">
                  <small
                    style={{ fontWeight: "bolder" }}
                    className={`${GlobalInfoTextColor} mr-25`}
                  >
                    Target Time <Icons.Clock size={12} /> :
                  </small>{" "}
                  <small className="text-body">
                    {" "}
                    {waypTargetTime
                      ? moment(waypTargetTime).format(WEB_DATETIME_FORMAT)
                      : "No Time Schedule Info"}{" "}
                  </small>
                </div>
              )}
            </Col>
            <Col>
              <FormGroup>
                <Label
                  for="CountryMulti"
                  style={{ fontWeight: "bolder" }}
                  className={`${GlobalInfoTextColor} mr-25 mt-2`}
                >
                  Description
                </Label>
                <Input
                  type="textarea"
                  style={{ height: "150px" }}
                  defaultValue={description}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  )
}

{
  /* Add WayPoint Modal */
}
export function AddWayPointModal({
  addWayPoint,
  getFilesWay,
  functionThatResetsTheFileInput,
  wayupdateLocation,
  setTONullLocation,
  comStates,
  setComStates,
  setWayTime,
  wayTime
}) {
  const { addModal, wayimage, wayaddress, LocationTag, description, inputkey } =
    comStates
  const dispatch = useDispatch()
  const isCloseGallery = useSelector((state) => state?.common?.isCloseGallery)
  return (
    <Modal
      isOpen={addModal}
      toggle={() => setComStates({ ...comStates, addModal: false })}
      className={`modal-dialog-centered ${ModalStyle.WideModal}`}
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => setComStates({ ...comStates, addModal: false })}
      >
        Add Waypoint
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row className={styles.gClosebtnJust}>
            <Button.Ripple
              data-tip={
                !isCloseGallery
                  ? "Open Images & Places"
                  : "Close Images & Places"
              }
              className={`btn-icon btn-sm`}
              color={`${isCloseGallery ? "danger" : "primary"}`}
              onClick={() => {
                OpenCloseGallery(dispatch, isCloseGallery)
              }}
            >
              {isCloseGallery ? (
                <Icons.X size={"16"} />
              ) : (
                <Icons.Image size={"16"} />
              )}
            </Button.Ripple>
          </Row>
          <Row>
            <Col lg={isCloseGallery ? "6" : "12"}>
              <Row>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup>
                    <div className={styles.DragDrop}>
                      {wayimage ? (
                        <img
                          className={styles.Preview}
                          src={wayimage}
                          width={150}
                          height={150}
                        />
                      ) : (
                        <p className={styles.DragText}>
                          Drag & Drop Image Upload
                        </p>
                      )}

                      <FormGroup className={styles.DropInput}>
                        <FileBase64
                          multiple={true}
                          onDone={(e) => getFilesWay(e)}
                          key={inputkey || ""}
                        />
                      </FormGroup>
                      <div style={{ position: "absolute" }}>
                        {wayimage ? (
                          <Button.Ripple
                            onClick={() => {
                              functionThatResetsTheFileInput()
                            }}
                            className="btn-icon btn-sm"
                            color="danger"
                          >
                            <Icons.Trash size={16} />
                          </Button.Ripple>
                        ) : null}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={isCloseGallery ? "12" : "6"}>
                  <FormGroup>
                    <Label htmlFor="lastNameMulti">Location or Address*</Label>
                    {wayaddress !== null ? (
                      <span
                        onClick={() => {
                          setTONullLocation()
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    {wayaddress !== null ? (
                      <Input
                        value={wayaddress || ""}
                        id="LocationTag"
                        name="LocationTag"
                        placeholder="Template Name"
                        disabled={wayaddress !== null}
                      />
                    ) : (
                      <div style={{ position: "relative" }}>
                        <GooglePlacesAutocomplete
                          apiKey={GPAutocompleteApi}
                          locationBoxStyle={"form-control"}
                          selectProps={{
                            defaultInputValue: wayaddress,
                            isClearable: true,
                            onChange: (val) => {
                              GetWikipediaData(val, wayupdateLocation, false)
                            }
                          }}
                        />
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <Label htmlFor="CountryMulti">Waypoint Name</Label>
                    {LocationTag !== null ? (
                      <span
                        onClick={() => {
                          setComStates({
                            ...comStates,
                            LocationTag: null
                          })
                        }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    <DelayInput
                      className={"form-control"}
                      type="text"
                      value={LocationTag || ""}
                      placeholder="Custom waypoint name"
                      onChange={(e) =>
                        setComStates({
                          ...comStates,
                          LocationTag: e.target.value
                        })
                      }
                    />
                  </FormGroup>
                  <FormGroup style={{ position: "relative" }}>
                    <Label htmlFor="CountryMulti">Time</Label>
                    {wayTime !== null ? (
                      <span
                        onClick={() => {
                          setWayTime(null)
                        }}
                        style={{ marginTop: "2px" }}
                        className={styles.clearbtn1}
                      >
                        <FaTimes fontSize={15} />
                      </span>
                    ) : null}
                    <Flatpickr
                      data-enable-time
                      id="date-time-picker"
                      className="form-control"
                      style={{ backgroundColor: "transparent" }}
                      value={wayTime || ""}
                      onChange={(e) => setWayTime(e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={"6"}>
              <GalleryExternalPlaces
                waypointStates={comStates}
                setWayPointStates={setComStates}
                TempData={comStates}
                setTempData={setComStates}
                updateStartLocation={wayupdateLocation}
                functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                checkStatus={"Waypoint"}
                isGalleryForDialoge={true}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12">
              <FormGroup className="position-relative">
                <Label htmlFor="CountryMulti">Description</Label>
                {description !== null ? (
                  <span
                    onClick={() => {
                      setComStates({
                        ...comStates,
                        description: null
                      })
                    }}
                    className={styles.clearbtn1}
                  >
                    <FaTimes fontSize={15} />
                  </span>
                ) : null}
                <DelayInput
                  className="form-control"
                  element="textarea"
                  value={description || ""}
                  style={{ height: "150px" }}
                  placeholder="Description"
                  onChange={(e) => {
                    setComStates({
                      ...comStates,
                      description: e.target.value
                    })
                  }}
                />
              </FormGroup>
            </Col>
            <TempLanguageField
              setTempData={setComStates}
              TempData={comStates}
            />
          </Row>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Row className="w-100">
          <Col lg="6" md="6" sm="6">
            <Button
              color="primary"
              className="w-100"
              onClick={() => setComStates({ ...comStates, addModal: false })}
            >
              cancel
            </Button>
          </Col>
          <Col lg="6" md="6" sm="6">
            <Button
              color="primary"
              className="w-100"
              onClick={() => addWayPoint()}
            >
              Add
            </Button>
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  )
}
{
  /* Detail Modal functional */
}
export const DetailModalFunc = ({ detailModal, setDetailModal }) => {
  return (
    <Modal
      isOpen={detailModal}
      toggle={() => setDetailModal(false)}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader toggle={() => setDetailModal(false)}>
        Detail Modal
      </ModalHeader>
      <ModalBody>
        <Form>detail...</Form>
      </ModalBody>
    </Modal>
  )
}

{
  /* QR Code Modal */
}
export const QRCodeLinkModal = ({
  QRCodeModal,
  qrValue,
  setShareTypeData,
  shareTypeData,
  copied,
  label
}) => {
  const [size, setSize] = useState(390)
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen")
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    const downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = `${qrValue}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 440) {
        setSize(200)
      } else {
        setSize(390)
      }
    })
  }, [])
  return (
    <Modal
      isOpen={QRCodeModal}
      toggle={() => setShareTypeData({ ...shareTypeData, QRCodeModal: false })}
      className="modal-dialog-centered"
    >
      <ModalHeader
        toggle={() =>
          setShareTypeData({ ...shareTypeData, QRCodeModal: false })
        }
      >
        Share QR Code and Link
      </ModalHeader>
      <ModalBody>
        <Card className="mb-3">
          <div className="App" style={{ textAlign: "center" }}>
            <h1>Invite to JellyRide {label}</h1>
            <br />
            <QRCode
              id="qr-gen"
              value={qrValue}
              size={size}
              level={"H"}
              includeMargin={true}
            />

            <p>
              <Col>
                <FormGroup>
                  <Label className="mt-1" htmlFor="CountryMulti">
                    {label} Link
                  </Label>
                  <Input
                    style={{ width: "91%", margin: "auto" }}
                    defaultValue={qrValue}
                    disabled
                  />
                </FormGroup>
              </Col>

              <CopyToClipboard
                text={qrValue}
                onCopy={() =>
                  setShareTypeData({ ...shareTypeData, copied: true })
                }
              >
                <Button color="primary">Copy to clipboard</Button>
              </CopyToClipboard>
            </p>
            <p>{copied ? <Badge color="warning">Copied.</Badge> : null}</p>

            <p>
              <Button color="primary" onClick={downloadQRCode}>
                Download QR Code
              </Button>
            </p>
          </div>
        </Card>
      </ModalBody>
    </Modal>
  )
}

export const QRCodeLinkModalObj = ({ label, comBStates, setBComStates }) => {
  const [size, setSize] = useState(390)
  const { copied, qrValue, QRCodeModal } = comBStates
  const downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen")
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    const downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = `${qrValue}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 440) {
        setSize(200)
      } else {
        setSize(390)
      }
    })
  }, [])
  return (
    <Modal
      isOpen={QRCodeModal}
      toggle={() => setBComStates({ ...comBStates, QRCodeModal: false })}
      className="modal-dialog-centered"
    >
      <ModalHeader
        toggle={() => setBComStates({ ...comBStates, QRCodeModal: false })}
      >
        Share QR Code and Link
      </ModalHeader>
      <ModalBody>
        <Card className="mb-3">
          <div className="App" style={{ textAlign: "center" }}>
            <h1>Invite to JellyRide {label}</h1>
            <br />
            <QRCode
              id="qr-gen"
              value={qrValue}
              size={size}
              level={"H"}
              includeMargin={true}
            />

            <p>
              <Col>
                <FormGroup>
                  <Label className="mt-1" htmlFor="CountryMulti">
                    {label} Link
                  </Label>
                  <Input
                    style={{ width: "91%", margin: "auto" }}
                    defaultValue={qrValue}
                    disabled
                  />
                </FormGroup>
              </Col>

              <CopyToClipboard
                text={qrValue}
                onCopy={() => setBComStates({ ...comBStates, copied: true })}
              >
                <Button color="primary">Copy to clipboard</Button>
              </CopyToClipboard>
            </p>
            <p>{copied ? <Badge color="warning">Copied.</Badge> : null}</p>

            <p>
              <Button color="primary" onClick={downloadQRCode}>
                Download QR Code
              </Button>
            </p>
          </div>
        </Card>
      </ModalBody>
    </Modal>
  )
}
