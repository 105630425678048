import { useDispatch, useSelector } from "react-redux"
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap"
export const DeleteConfModal = ({
  setDeleteModal,
  deleteOrderId,
  deleteModal,
  onRemoveItem,
  title,
  SetItems,
  isGroupInfo,
  setRange
}) => {
  const filterOption = useSelector((state) => state.tripsgroups)
  const dispatch = useDispatch()

  return (
    <Modal
      isOpen={deleteModal}
      toggle={() => setDeleteModal(false)}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader toggle={() => setDeleteModal(false)}>
        Delete {title}
      </ModalHeader>
      <ModalBody>Are you sure to want to delete {title}?</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => setDeleteModal(false)}>
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={() => {
            onRemoveItem(
              deleteOrderId,
              SetItems,
              isGroupInfo,
              setRange,
              filterOption,
              dispatch
            )
            setDeleteModal(false)
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const DeleteConfDailog = ({
  setComStates,
  comStates,
  deleteModal,
  onRemoveItem,
  title,
  deleteOrderId
}) => {
  const dispatch = useDispatch()
  return (
    <Modal
      isOpen={deleteModal}
      toggle={() => setComStates({ ...comStates, deleteModal: false })}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => setComStates({ ...comStates, deleteModal: false })}
      >
        Delete {title}
      </ModalHeader>
      <ModalBody>Are you sure to want to delete {title}?</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => setComStates({ ...comStates, deleteModal: false })}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={() => {
            onRemoveItem(deleteOrderId, dispatch)
            setComStates({ ...comStates, deleteModal: false })
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const DeleteMediaGalleryDailog = ({
  setComStates,
  comStates,
  title,
  DeleteMediaGallery
}) => {
  return (
    <Modal
      isOpen={comStates.deleteModal}
      toggle={() => setComStates({ ...comStates, deleteModal: false })}
      className="modal-dialog-centered"
      modalClassName="danger"
    >
      <ModalHeader
        toggle={() => setComStates({ ...comStates, deleteModal: false })}
      >
        Delete {title}
      </ModalHeader>
      <ModalBody>Are you sure to want to delete {title}?</ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => setComStates({ ...comStates, deleteModal: false })}
        >
          Cancel
        </Button>
        <Button
          color="danger"
          onClick={() => {
            DeleteMediaGallery()
            setComStates({ ...comStates, deleteModal: false })
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  )
}
