import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { Badge } from "reactstrap"

export const MediaTypeView = (mediaType, MediaFileUrl, isMediaTable) => {
  const docs = [{ uri: MediaFileUrl }]
  const config = {
    header: {
      disableHeader: true
    }
  }
  switch (mediaType) {
    case 2:
      return (
        <video
          style={{
            width: isMediaTable ? "200px" : "100%",
            height: isMediaTable ? "200px" : "100%"
          }}
          controls
          preload='metadata'
          src={MediaFileUrl}
        />
      )
    case 3:
      return (
        <div style={{ textAlign: "center" }}>
          <a href={MediaFileUrl} target="_blank" rel="noopener noreferrer">
            <Badge className="my-1">Preview</Badge>
          </a>
          <DocViewer
            config={config}
            key={Math.random()}
            pluginRenderers={DocViewerRenderers}
            documents={docs}
            style={{ height: "200px", width: "200px" }}
          />
        </div>
      )
    case 4:
      return (
        <audio
          style={{
            width: isMediaTable ? "200px" : "100%",
            height: isMediaTable ? "200px" : "100%"
          }}
          controls
          src={MediaFileUrl}
        />
      )
    default:
      return <Badge color="secondary">{mediaType}</Badge>
  }
}
