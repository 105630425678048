import { EDIT_ORGANIZATION, EDIT_ORGANIZATION_MODAL_OPEN } from "../../constants/AdminPanelConstants"

// ** Initial State
const initialState = {
  organization: {},
  isEditModalOpen: false
}

const CreateTripReducer = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_ORGANIZATION:
      return { ...state, organization: action.data }
    case EDIT_ORGANIZATION_MODAL_OPEN:
      return { ...state, isEditModalOpen: action.data }
    default:
      return state
  }
}

export default CreateTripReducer
