import axios from "axios"
import { toast } from "react-toastify"
import { BASE_MEDIA } from "../../../GlobalURL/URL"
import { GET_MEDIA_GALLERY } from "../../constants/MediaGallery"
import { LOADING_FOR_API_CLICK } from "../../constants/Spinner"

// Update TripInfo
export const GetMediaGalleryAction = (Guid, parentMedia, mediaType) => {
  const postData = {
    parentGuid: Guid,
    mediaParent: parentMedia,
    mediaType
  }
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: JSON.parse(localStorage.getItem("Token"))
    }
  }
  return (dispatch) => {
    return axios
      .post(`${BASE_MEDIA}/GetMediaItems`, postData, axiosConfig)
      .then((response) => {
        if (response.data.ShowMsg) {
          toast.error(`${response.data.ErrorMsg}`)
          return null
        } else {
          dispatch({ type: GET_MEDIA_GALLERY, data: response.data })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err)
      })
  }
}

export const DeleteMediaGalleryAction = (
  MediaData,
  parentMedia,
  parentGuid
) => {
  const postData = {
    parentGuid,
    mediaParent: parentMedia,
    mediaItems: [
      {
        mediaFileName: null,
        mediaFileTempId: null,
        mediaFileUrl: MediaData.MediaFileUrl,
        mediaThumbUrl: null,
        mediaType: MediaData.MediaType,
        mediaExtension: null,
        mediaBytes: null,
        mediaEncoded: null,
        isRemoved: true,
        mediaNameTag: MediaData.MediaNameTag,
        parentGuid,
        mediaParent: parentMedia,
        language: MediaData.Language,
        mediaGuid: MediaData.MediaGuid,
        isDefault: MediaData.isDefault,
        isPublic: MediaData.isPublic
      }
    ]
  }
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      accept: "*/*",
      Authorization: JSON.parse(localStorage.getItem("Token"))
    }
  }
  return (dispatch) => {
    dispatch({ type: LOADING_FOR_API_CLICK, data: true })
    return axios
      .post(`${BASE_MEDIA}/UpdateMediaItems`, postData, axiosConfig)
      .then((response) => {
        if (response.data.ShowMsg) {
          toast.error(`${response.data.ErrorMsg}`)
          return dispatch({ type: LOADING_FOR_API_CLICK, data: false })
        } else {
          // dispatch({ type: GET_MEDIA_GALLERY, data: response.data })
          dispatch(
            GetMediaGalleryAction(parentGuid, parentMedia, MediaData.MediaType)
          )
          toast.success("Media has been Deleted Successfully")
          dispatch({ type: LOADING_FOR_API_CLICK, data: false })
        }
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err)
        dispatch({ type: LOADING_FOR_API_CLICK, data: false })
      })
  }
}
