import React from "react"
import { useSelector } from "react-redux"
import { Spinner } from "reactstrap"

const ApiButtonTitle = ({ title, isBigSize }) => {
  const { isloading } = useSelector((state) => state?.loading)
  return (
    <span>
      {isloading ? (
        <div >
          <span style={{fontFamily:"Inter", fontWeight:'500', fontStyle:'normal', fontSize:'14px'}}>{title}</span> <Spinner style={{marginLeft:'5px'}} size={isBigSize ? 'md' : 'sm'} />
        </div>
      ) : (
        <span style={{fontFamily:"Inter", fontWeight:'500', fontStyle:'normal', fontSize:'14px'}}>{title}</span>
      )}
    </span>
  )
}

export default ApiButtonTitle
