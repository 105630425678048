import {
  DRAGGED_WAYPOINTS,
  END_DELETED_REQUEST,
  GET_CONTACTS_TRIP_CREATE_SCREEN,
  IS_TEMPLATE_MODAL_TRUE,
  IS_TEMPLATE_TRUE,
  START_END_DELETED_REQUEST
} from "../../constants/CreateTripConstants"

// ** Initial State
const initialState = {
  templatesList: [],
  contactsList: [],
  draggedWaypoints: [],
  EndStartDelete: false,
  EndDelete: false,
  isTemplateSureModal: false,
  isTemplateSureTrue: false
}

const CreateTripReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS_TRIP_CREATE_SCREEN:
      return { ...state, contactsList: action.data }
    case DRAGGED_WAYPOINTS:
      return { ...state, draggedWaypoints: action.data }
    case START_END_DELETED_REQUEST:
      return { ...state, EndStartDelete: action.data }
    case END_DELETED_REQUEST:
      return { ...state, EndDelete: action.data }
    case IS_TEMPLATE_MODAL_TRUE:
      return { ...state, isTemplateSureModal: action.data }
    case IS_TEMPLATE_TRUE:
      return { ...state, isTemplateSureTrue: action.data }
    default:
      return state
  }
}

export default CreateTripReducer
