import axios from "axios"
import {
  GET_CURRENT_DROP_DOWN_LANG_CODE,
  GET_CURRENT_LANG_CODE
} from "../../../redux/constants/Common"
import { DetectLanguageApi } from "../../../utility/ApiKeys/ApiKey"

const DetectLanguage = async (description, dispatch) => {
  const postData = {
    q: description
  }
  if (postData?.q === "" || postData?.q === null) {
    dispatch({
      type: GET_CURRENT_LANG_CODE,
      data: "en"
    })
    dispatch({
      type: GET_CURRENT_DROP_DOWN_LANG_CODE,
      data: "en"
    })
    return "en"
  }
  return new Promise(function (resolve, reject) {
    const otmAPI = `https://translation.googleapis.com/language/translate/v2/detect?key=${DetectLanguageApi}`
    axios
      .post(otmAPI, postData)
      .then(
        (response) => {
          dispatch({
            type: GET_CURRENT_LANG_CODE,
            data: response.data?.data?.detections[0][0].language
          })
          // dispatch({
          //   type: GET_CURRENT_DROP_DOWN_LANG_CODE,
          //   data: response.data?.data?.detections[0][0].language
          // })
        }
        // resolve(response.data?.data?.detections[0][0].language)
      )
      .catch(function (err) {
        console.log("Fetch Error :-S", err)
      })
  })
}

export default DetectLanguage
