
import { BASE_STORAGE } from "../GlobalURL/URL"

export const WaypointTagTypeColors = 
    {
        Landmarks: '#29b1db',
        Warnings: '#f19a9a',
        Places: '#8588e5',
        Transport: '#A15D98',
        Lifestyle: '#25da91',
        InfoAndServices: '#4382BB',
        SpecialTags: '#F4C815',
        Undefined: '#b2c7cb'
    }

export const WaypointTagTyps = [
    {
        Enum: 0,
        Label: 'No tag',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Undefined.svg`,
        Color: WaypointTagTypeColors.Undefined,
        SearchTag: 'No tag'
    },
    {
        Enum: 1,
        Label: 'Must see',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Must%20See.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Must see Attraction'
    },
    {
        Enum: 2,
        Label: 'Meet here',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Meet%20Here.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Meet here Meeting point'
    },
    {
        Enum: 3,
        Label: 'Local sight',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Local%20Sight.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Local sight Monument History'
    },
    {
        Enum: 4,
        Label: 'Interesting',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Interesting.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Interesting Attraction Cheap Affordable Recommended'
    },
    {
        Enum: 5,
        Label: 'Not Interesting',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Nothing%20Interesting.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Not Interesting Boring Expensive'
    },
    {
        Enum: 6,
        Label: 'Local attraction',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Local%20Attraction.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Local attraction Tourist attraction'
    },
    {
        Enum: 7,
        Label: 'Museum',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Museum.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Museum Culture History'
    },
    {
        Enum: 8,
        Label: 'Take a photo',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Photo%20Pin.svg`,
        Color: WaypointTagTypeColors.Landmarks,
        SearchTag: 'Take a photo'
    },
    {
        Enum: 20,
        Label: 'Avoid',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Avoid.svg`,
        Color: WaypointTagTypeColors.Warnings,
        SearchTag: 'Avoid Danger'
    },
    {
        Enum: 21,
        Label: 'Traffic jam',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Traffic%20Jam.svg`,
        Color: WaypointTagTypeColors.Warnings,
        SearchTag: 'Traffic jam Slow'
    },
    {
        Enum: 22,
        Label: 'Bad road',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Bad%20Road.svg`,
        Color: WaypointTagTypeColors.Warnings,
        SearchTag: 'Bad road Bumpy road'
    },
    {
        Enum: 23,
        Label: 'Road accident',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Car%20Accident.svg`,
        Color: WaypointTagTypeColors.Warnings,
        SearchTag: 'Road accident'
    },
    {
        Enum: 24,
        Label: 'Constructions works',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Construction%20Works.svg`,
        Color: WaypointTagTypeColors.Warnings,
        SearchTag: 'Construction works Blocked road'
    },
    {
        Enum: 25,
        Label: 'Police on the road',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Police%20on%20the%20Road.svg`,
        Color: WaypointTagTypeColors.Warnings,
        SearchTag: 'Police on the road Police radar Police control'
    },
    {
        Enum: 31,
        Label: 'Place to eat',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Good%20Restaurant_2.svg`,
        Color: WaypointTagTypeColors.Places,
        SearchTag: 'Food Restaurant Dinner Breakfast Brunch Lunch Fast food Place to eat Grill Barbecue Traditional cuisine'
    },
    {
        Enum: 32,
        Label: 'Coffee Bar',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Good%20Bar.svg`,
        Color: WaypointTagTypeColors.Places,
        SearchTag: 'Coffee bar Drink Cocktail Lounge Beverage'
    },
    {
        Enum: 33,
        Label: 'Nightlife area',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Good%20place%20for%20night%20life.svg`,
        Color: WaypointTagTypeColors.Places,
        SearchTag: 'Nightlife area Club Disco Party Live music Clubbing Concert Music venue'
    },
    {
        Enum: 34,
        Label: 'Shopping area',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Must%20See.svg`,
        Color: WaypointTagTypeColors.Places,
        SearchTag: 'Shopping area Shopping center Shopping Mall Shops'
    },
    {
        Enum: 35,
        Label: 'Good for kids',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Good%20for%20Kids.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Good for kids Playground'
    },
    {
        Enum: 36,
        Label: 'Pets friendly',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Pets%20Friendly.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Pets friendly Dogs Cats'
    },
    {
        Enum: 37,
        Label: 'Souvenir shop',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Souverir%20Shop.svg`,
        Color: WaypointTagTypeColors.Places,
        SearchTag: 'Souvenir shop Postcards Memory'
    },
    {
        Enum: 38,
        Label: 'Beach bar',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Beach%20Bar.svg`,
        Color: WaypointTagTypeColors.Places,
        SearchTag: 'Beach bar Beach club Cocktail Party'
    },
    {
        Enum: 39,
        Label: 'Market place',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Marketplace.svg`,
        Color: WaypointTagTypeColors.Places,
        SearchTag: 'Market place Grocery'
    },
    {
        Enum: 41,
        Label: 'Parking spot',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Parking%20Place.svg`,
        Color: WaypointTagTypeColors.Transport,
        SearchTag: 'Parking spot Parking place Parking lot'
    },
    {
        Enum: 42,
        Label: 'EV charging station',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_e-Charging%20Station.svg`,
        Color: WaypointTagTypeColors.Transport,
        SearchTag: 'EV charging station Electrical vehicle Battery'
    },
    {
        Enum: 43,
        Label: 'Rent-a-bike',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Rent%20a%20Bike.svg`,
        Color: WaypointTagTypeColors.Transport,
        SearchTag: 'Rent-a-bike'
    },
    {
        Enum: 44,
        Label: 'Rent-a-boat',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Rent%20a%20Boat.svg`,
        Color: WaypointTagTypeColors.Transport,
        SearchTag: 'Rent-a-boat'
    },
    {
        Enum: 45,
        Label: 'Rent-a-car',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Rent%20a%20Car.svg`,
        Color: WaypointTagTypeColors.Transport,
        SearchTag: 'Rent-a-car'
    },
    {
        Enum: 46,
        Label: 'Transport spot',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Transport%20Spot.svg`,
        Color: WaypointTagTypeColors.Transport,
        SearchTag: 'Transport spot Bus station Taxi station Pick up'
    },
    {
        Enum: 81,
        Label: 'Sea & beach',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Beach.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Sea & beach Pool Swimming Kayak Surf Rowing'
    },
    {
        Enum: 82,
        Label: 'Park & stroll',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Nature%20Park.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Park & stroll Walking Garden'
    },
    {
        Enum: 84,
        Label: 'Sport & Recreation',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Sport%20%26%20Recreation.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Sport & Recreation Football Soccer Tennis Volleyball Basketball Running Gym'
    },
    {
        Enum: 85,
        Label: 'Take rest spot',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Take%20Rest.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Take rest spot Relax'
    },
    {
        Enum: 86,
        Label: 'Bike friendly',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Pets%20Friendly.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Bike friendly Bike route'
    },
    {
        Enum: 87,
        Label: 'Wellness & health',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Wellness.svg`,
        Color: WaypointTagTypeColors.Lifestyle,
        SearchTag: 'Wellness & health Peaceful'
    },
    {
        Enum: 101,
        Label: 'Accommodation',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Accomodation_2.svg`,
        Color: WaypointTagTypeColors.InfoAndServices,
        SearchTag: 'Accommodation Housing Sleeping Motel Hotel'
    },
    {
        Enum: 102,
        Label: 'Free WiFi',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Free%20Wi-Fi.svg`,
        Color: WaypointTagTypeColors.InfoAndServices,
        SearchTag: 'Free WiFi Internet Upload Download'
    },
    {
        Enum: 103,
        Label: 'Info tag',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_General%20Tag%20Info.svg`,
        Color: WaypointTagTypeColors.InfoAndServices,
        SearchTag: 'Info tag Information'
    },
    {
        Enum: 104,
        Label: 'Hospital',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Hospital_2.svg`,
        Color: WaypointTagTypeColors.InfoAndServices,
        SearchTag: 'Hospital Ambulance First aid Infirmary'
    },
    {
        Enum: 105,
        Label: 'Police office',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Police%20Office.svg`,
        Color: WaypointTagTypeColors.InfoAndServices,
        SearchTag: 'Police office Police station Security'
    },
    {
        Enum: 106,
        Label: 'Tourist office',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Tourist%20Office.svg`,
        Color: WaypointTagTypeColors.InfoAndServices,
        SearchTag: 'Tourist office Tourist board Info office Guide Tour Travel Accomodation Things to do Wine Eco Gastro Cyclo route'
    },
    {
        Enum: 501,
        Label: 'Custom info',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_General%20Tag%20Info.svg`,
        Color: WaypointTagTypeColors.InfoAndServices,
        SearchTag: 'Custom info General info'
    },
    {
        Enum: 502,
        Label: 'Photo tag',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Photo%20Pin.svg`,
        Color: WaypointTagTypeColors.SpecialTags,
        SearchTag: 'Photo tag Picture Video'
    },
    {
        Enum: 999,
        Label: 'No info',
        Icon: `${BASE_STORAGE}/public/custom_tag_icons/Iconset_Undefined.svg`,
        Color: WaypointTagTypeColors.Undefined,
        SearchTag: 'No info'
    }
]