import { ORDERING_SELECTED_ITEM } from "../../constants/TripInfo"

// **  Initial State
const initialState = {
  SelctedItem: []
}

const tripinfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORDERING_SELECTED_ITEM:
      return {
        ...state,
        SelctedItem: action.data
      }
    default:
      return state
  }
}

export default tripinfoReducer
