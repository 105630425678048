import { UPDATE_TRIP_INFO } from "../../constants/TripConstants"

// ** Initial State
const initialState = {
    tripinfo: [],
    success:false
  }
  
  const TripReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_TRIP_INFO:
        return { ...state, success:action.data}
      default:
        return state
    }
  }
  
  export default TripReducer
  