export const ADD_OFFER_SUCCESS = "ADD_OFFER_SUCCESS"
// For language of description
export const GET_CURRENT_LANG_CODE = "GET_CURRENT_LANG_CODE"
export const GET_CURRENT_DROP_DOWN_LANG_CODE = "GET_CURRENT_DROP_DOWN_LANG_CODE"
export const RESET_CURRENT_LANG_CODE = "RESET_CURRENT_LANG_CODE"
export const CLOSE_GALLERY_PERMANENT = "CLOSE_GALLERY_PERMANENT"
export const IS_CLOSE_GALLERY = "IS_CLOSE_GALLERY"
export const GALLERY_SEARCH_REDUX = "GALLERY_SEARCH_REDUX"
export const GetAvailabilityCalendarDates = "GetAvailabilityCalendarDates"

// For TemplateList for Waypoints and tags
export const SET_GET_TEMPLATE_LIST = "SET_GET_TEMPLATE_LIST"
export const SET_GET_TEMPLATE_TAGS_LIST = "SET_GET_TEMPLATE_TAGS_LIST"
export const SHOW_AI_RESULT = "SHOW_AI_RESULT"

// AiItinerary

export const GET_ITINERARY_GUID = "GET_ITINERARY_GUID"
export const IS_ITINERARY_LOADED = "IS_ITINERARY_LOADED"
export const GET_AIITINERARY_TRIPS = "GET_AIITINERARY_TRIPS"
export const GET_SOCIAL_LOGINS = "GET_SOCIAL_LOGINS"
export const GET_STATIC_AIITINERARY_TRIPS = "GET_STATIC_AIITINERARY_TRIPS"
export const GET_USER_AIITINERARY_TRIPS = "GET_USER_AIITINERARY_TRIPS"
export const IS_USER_AIITINERARY_TRIPS = "IS_USER_AIITINERARY_TRIPS"
export const IS_Ai_Price_Modal = "IS_Ai_Price_Modal"
export const SOCIAL_SHARING_MODAL = "SOCIAL_SHARING_MODAL"
export const IsFindAgencyModal = "IsFindAgencyModal"

// Contacts

export const IS_CONTACT_IMPORT_MODAL = "IS_CONTACT_IMPORT_MODAL"

//

export const GET_MAPS_LOC = "GET_MAPS_LOC"

// Getting locality and country

export const Auto_Place_Destructuring = "Auto_Place_Destructuring"

// offerdetails

export const OfferDetails_Data = "OfferDetails_Data"
