import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import styles from '../../../styles/ItemsStyle/externalplace.module.scss'
import ImgGallery from '../../common/CommFunctions/ImgGallery'
import GeoObjectListShow from './GeoObjectListShow'
const GalleryExternalPlaces = ({
    setTempData,
    TempData,
    geoObjectList,
    handlePagination,
    handleTemplateSearch,
    updateStartLocation,
    ResetAllValuesToNull,
    functionThatResetsTheFileInput,
    active,
    onAddTemplate,
    checkStatus,
    setWayPointStates,
    waypointStates,
    setGeoObjectList,
    isGalleryForDialoge
}) => {
    const isCloseGallery = useSelector(state => state?.common?.isCloseGallery)
    const CheckImageGallery = () => {
        if (checkStatus === "Waypoint") {
            return <ImgGallery setTempData={setTempData} TempData={TempData} setWaypointStates={setWayPointStates} WaypointStates={waypointStates} ComStatus={checkStatus}/>
        }
        if (checkStatus === "UpdateWaypoint") {
            return <ImgGallery setTempData={setWayPointStates} TempData={waypointStates} ComStatus={checkStatus}/>
        }
        if (checkStatus === "Template") {

            return <ImgGallery setTempData={setTempData} TempData={TempData} ComStatus={checkStatus}/>
        }
        if (checkStatus === "WaypointTag") {

            return <ImgGallery setTempData={setTempData} TempData={TempData} ComStatus={checkStatus}/>
        }
        if (checkStatus === "CreateTrip") {

            return <ImgGallery setTempData={setTempData} TempData={TempData} ComStatus={checkStatus}/>
        }
        if (checkStatus === "CreateTripStartW") {

            return <ImgGallery setTempData={setTempData} TempData={TempData} ComStatus={checkStatus}/>
        }
        if (checkStatus === "CreateTripEndW") {

            return <ImgGallery setTempData={setTempData} TempData={TempData} ComStatus={checkStatus}/>
        }
        if (checkStatus === "Default") {
            return <ImgGallery setTempData={setTempData} TempData={TempData} ComStatus={checkStatus}/>
        }
    }
    return (
        <>
          <Col lg={'12'} md={'12'} sm={'12'} className={isCloseGallery ? styles.GPOpenContainer : styles.GPCloseContainer}>
                    <Card>
                        <Row>
                            <Col lg={'12'} md='12' sm='6'>
                                {
                                    CheckImageGallery()
                                }
                            </Col>
                            <Col lg={'12'} style={{display:`${isGalleryForDialoge ? 'none' : 'block'}`}} md='12' sm="6">
                                <div>
                                    {
                                        active === '1' ? <div className={styles.GeoContainer}>
                                            <GeoObjectListShow geoObjectList={geoObjectList} ResetAllValuesToNull={ResetAllValuesToNull} setTempData={setTempData}
                                                TempData={TempData} onAddTemplate={onAddTemplate} functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                                                updateStartLocation={updateStartLocation} handlePagination={handlePagination}
                                                handleTemplateSearch={handleTemplateSearch} setGeoObjectList={setGeoObjectList}
                                            />
                                        </div> : null
                                    }
                                    {
                                        active === '2' ? <div className={styles.GeoContainer}>
                                            <GeoObjectListShow geoObjectList={geoObjectList} ResetAllValuesToNull={ResetAllValuesToNull} setTempData={setTempData}
                                                TempData={TempData} onAddTemplate={onAddTemplate} functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                                                updateStartLocation={updateStartLocation} handlePagination={handlePagination}
                                                handleTemplateSearch={handleTemplateSearch} setGeoObjectList={setGeoObjectList}
                                            />
                                        </div> : null
                                    }
                                    {
                                        active === '3' ? <div className={styles.GeoContainer}>
                                            <GeoObjectListShow geoObjectList={geoObjectList} ResetAllValuesToNull={ResetAllValuesToNull} setTempData={setTempData}
                                                TempData={TempData} onAddTemplate={onAddTemplate} functionThatResetsTheFileInput={functionThatResetsTheFileInput}
                                                updateStartLocation={updateStartLocation} handlePagination={handlePagination}
                                                handleTemplateSearch={handleTemplateSearch} setGeoObjectList={setGeoObjectList}
                                            />
                                        </div> : null
                                    }

                                </div>
                            </Col>
                        </Row>

                    </Card>
                </Col> 
        </>
    )
}

export default GalleryExternalPlaces