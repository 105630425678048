import { UPDATE_GROUP } from "../../constants/GroupsConstants"

// ** Initial State
const initialState = {
    tripinfo: [],
    success:false
  }
  
  const GroupReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_GROUP:
        return { ...state, tripinfo:"hello"}
      default:
        return state
    }
  }
  
  export default GroupReducer
  