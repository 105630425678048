import { useEffect, useState } from "react"
import { ChevronLeft, Search } from "react-feather"
import { useDispatch } from "react-redux"
import { Button, Container, Input, Spinner } from "reactstrap"
import {
  GET_CURRENT_LANG_CODE,
  GET_MAPS_LOC
} from "../../../redux/constants/Common"
import styles from "../../../styles/ItemsStyle/externalplace.module.scss"
import GeoObjectDetailDialog from "../../CommonApis/Template/GeoObjectDetailDialog"
import { GetGeoName } from "../../CommonApis/Template/GetGeoObjectList"
import DetectLanguageApi from "../../CommonApis/TranslatorApi/DetectLanguageApi"

const GeoObjectListShow = ({
  geoObjectList,
  setTempData,
  TempData,
  onAddTemplate,
  ResetAllValuesToNull,
  functionThatResetsTheFileInput,
  setGeoObjectList,
  updateStartLocation
}) => {
  const dispatch = useDispatch()
  const [currentLimit, setCurrentLimit] = useState(0)
  const [IsDataLoading, setIsDataLoading] = useState(true)
  const [IsCliked, setIsCliked] = useState(false)
  const [clickedQuery, setClikedQuery] = useState("")
  const [comStates, setComStates] = useState({
    languagecode: "",
    languagelabel: ""
  })
  const { searchtemplate, resLimit, geoSearch } = TempData
  const GetItemDetail = (xid) => {
    return new Promise(function (resolve, reject) {
      const otmAPI = `https://api.opentripmap.com/0.1/en/places/xid/${xid}?apikey=${"5ae2e3f221c38a28845f05b608b55a01f49b4c75337db5dd2bb415b9"}`
      fetch(otmAPI)
        .then((response) => response.json())
        .then((data) => resolve(data))
        .catch(function (err) {
          console.log("Fetch Error :-S", err)
        })
    })
  }
  const GetResponse = async (xid) => {
    dispatch({
      type: GET_CURRENT_LANG_CODE,
      data: ""
    })
    let pickAddress = null
    let pickmapsLocationTag = null
    let mergedAddress = ""
    const res = await GetItemDetail(xid)
    if (res) {
      const pick = (obj, arr) =>
        arr.reduce(
          (acc, record) => (record in obj && (acc[record] = obj[record]), acc),
          {}
        )
      pickAddress = pick(res?.address, [
        "neighbourhood",
        "town",
        "city",
        "country"
      ])
      const pickRoadHousNo = pick(res?.address, [
        "road",
        "pedestrian",
        "street",
        "house_number"
      ])
      pickmapsLocationTag = pick(res?.address, ["town", "city", "country"])
      if (Object.values(pickRoadHousNo).length === 0) {
        mergedAddress = Object.values(pickRoadHousNo)
          .join(" ")
          .concat(Object.values(pickAddress).join(", "))
      } else {
        mergedAddress = Object.values(pickRoadHousNo)
          .join(" ")
          .concat(", ", Object.values(pickAddress).join(", "))
      }
      DetectLanguageApi(res?.wikipedia_extracts?.text, dispatch)
      if (res?.wikipedia_extracts?.text) {
        dispatch({
          type: GET_MAPS_LOC,
          data: Object.values(pickmapsLocationTag).join(" ")
        })
        setTempData({
          ...TempData,
          ismainscreen: false,
          LocationTag: res.name,
          image: res?.preview?.source,
          address: mergedAddress,
          lat: res?.point?.lat,
          lng: res?.point?.lon,
          thumb: res?.preview?.source,
          regular: res?.preview?.source,
          mapsLocationTag: Object.values(pickmapsLocationTag).join(" "),
          description: res?.wikipedia_extracts?.text,
          Addopen: true,
          DisableMode: false,
          waypfile: null,
          wayfile: null,
          OriginTemplate: null,
          gallerySearch: mergedAddress
        })
      } else {
        dispatch({
          type: GET_MAPS_LOC,
          data: Object.values(pickmapsLocationTag).join(" ")
        })
        setTempData({
          ...TempData,
          ismainscreen: false,
          LocationTag: res.name,
          image: res?.preview?.source,
          address: mergedAddress,
          lat: res?.point?.lat,
          lng: res?.point?.lon,
          thumb: res?.preview?.source,
          regular: res?.preview?.source,
          mapsLocationTag: Object.values(pickmapsLocationTag).join(" "),
          description: res?.wikipedia_extracts?.text,
          Addopen: true,
          DisableMode: false,
          waypfile: null,
          wayfile: null,
          OriginTemplate: null
        })
      }
    }
  }
  useEffect(() => {
    const timeOut = setTimeout(() => {
      // if (searchtemplate !== '') {
      //     GetGeoName(searchtemplate, setGeoObjectList, 0, setIsDataLoading, setCurrentLimit)
      //     // setTempData({ ...TempData, geoSearch: searchtemplate })
      // } else {
      //     setGeoObjectList([])
      //     // setTempData({ ...TempData, resLimit: 0, geoSearch: '' })
      // }
      if (geoSearch !== "") {
        GetGeoName(geoSearch, setGeoObjectList, 0, setIsDataLoading)
        // setTempData({ ...TempData, geoSearch })
      } else {
        setGeoObjectList([])
        setCurrentLimit(0)
        // setTempData({ ...TempData, resLimit: 0, geoSearch: '' })
      }
      if (IsCliked && clickedQuery !== geoSearch) {
        setCurrentLimit(0)
      }
      if (currentLimit >= 1) {
        GetGeoName(geoSearch, setGeoObjectList, currentLimit, setIsDataLoading)
        // setTempData({ ...TempData, geoSearch })
      } else {
        setGeoObjectList([])
        setCurrentLimit(0)

        // setTempData({ ...TempData, resLimit: 0, geoSearch: '' })
      }
    }, 1000)

    return () => clearTimeout(timeOut)
  }, [searchtemplate, geoSearch, currentLimit])
  const handlePagination = () => {
    const limit = currentLimit + 15
    setIsDataLoading(false)
    setIsCliked(true)
    setClikedQuery(geoSearch)
    setCurrentLimit(limit)
  }
  return (
    <>
      <GeoObjectDetailDialog
        updateStartLocation={updateStartLocation}
        functionThatResetsTheFileInput={functionThatResetsTheFileInput}
        ResetAllValuesToNull={ResetAllValuesToNull}
        onAddTemplate={onAddTemplate}
        setTempData={setTempData}
        TempData={TempData}
        comBStates={comStates}
        setComStates={setComStates}
      />
      <div>
        <div className={styles.gSection}>
          {/* <Button.Ripple data-tip={isCloseGallery ? 'Open Images & Places' : 'Close Images & Places'} className={`btn-icon btn-sm ${styles.gClosebtn}`} color='primary' onClick={() => {
                    setComStates({ ...comStates, isCloseGallery: !isCloseGallery })
                }}>
                    <ChevronLeft size={'16'} />
                </Button.Ripple> */}
          <div className="mt-2">
            <Input
              id="LocationTag"
              value={geoSearch}
              name="LocationTag"
              placeholder="Search External Places"
              className={styles.searchGallery}
              onChange={(e) => {
                setTempData({ ...TempData, geoSearch: e.target.value })
              }}
            />
            {/* <Button.Ripple data-tip={"Search Gallery Image"} color='primary' className={`${styles.GsearchBtn} btn-icon btn-sm`}>
                        <Search size={16} />
                    </Button.Ripple> */}
            {!geoObjectList?.data ? (
              <div className={styles.ImgCon}>
                <p className={styles.ResultNotFound}>No places found</p>
              </div>
            ) : (
              <Container className={styles.ImgCon}>
                {
                  IsDataLoading ? (
                    geoObjectList?.data?.length !== 0 ? (
                      geoObjectList?.data?.map((item) => (
                        <p
                          key={item.xid}
                          className={styles.GeoItem}
                          onClick={() => GetResponse(item.xid)}
                        >
                          {item.name}
                        </p>
                      ))
                    ) : (
                      <div className={styles.ResultNotFound}>
                        No places found
                      </div>
                    )
                  ) : (
                    <div className={styles.ImgGSpinner}>
                      <Spinner color="primary" />
                    </div>
                  )
                  // geoObjectList?.length !== 0 ? geoObjectList?.map(item => <p key={item.xid} className={styles.GeoItem} onClick={() => GetResponse(item.xid)}>{item.name}</p>) : <div className={styles.ResultNotFound}>
                  //     <p>Search For External Places</p>
                  // </div>
                }
              </Container>
            )}

            {currentLimit + 15 > geoObjectList?.count ? null : (
              <div>
                {geoObjectList?.length !== 0 ? (
                  <>
                    <div className={styles.paginationBox}>
                      <Button
                        size={16}
                        disabled={
                          currentLimit + 15 > geoObjectList?.count ||
                          !IsDataLoading
                        }
                        className={`btn-icon btn-sm`}
                        style={{ padding: "12px" }}
                        color="primary"
                        onClick={() => handlePagination()}
                      >
                        Next 15
                      </Button>
                    </div>
                    <div style={{ textAlign: "center", padding: "5px" }}>
                      <p>
                        Records {currentLimit + 15} of {geoObjectList?.count}
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default GeoObjectListShow
