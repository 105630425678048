import axios from "axios"
import { toast } from "react-toastify"

export const GetWikipediaData = (val, updateStartLocation, isExteranl) => {
  if (val !== null) {
    const postData = {
      localTime: "string"
    }
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
        Authorization: JSON.parse(localStorage.getItem("Token"))
      }
    }
    axios
      .get(
        ` https://en.wikipedia.org/api/rest_v1/page/summary/${val.value.structured_formatting.main_text
          .replace(/\s+/g, "_")
          .toLowerCase()}`,
        postData,
        axiosConfig
      )
      .then((res) => {
        if (res.data.title === "Not found.") {
          toast.error(`${res.data?.title}`)

          return null
        } else {
          updateStartLocation(val, res.data, isExteranl)
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          updateStartLocation(val, null, isExteranl)
        }
        console.log("AXIOS ERROR: ", error)
      })
  } else {
    console.log("Service is not available at the moment. Please try later.")
  }
}
