import {
  EXCLUDED_OFFER_ITEMS,
  GET_Business_Details,
  GET_Organization_Details,
  INCLUDED_OFFER_ITEMS,
  IS_PREVIEW_DISABLED,
  IS_Organization_PRofile,
  IS_Organization_PRofile_Home_Click,
  IS_Organization_Load
} from "../../constants/Organization"

// **  Initial State
const initialState = {
  BusinessDetails: {},
  OrganizationDetails: null,
  isPreviewDisabled: false,
  ExcludedItemsList: [],
  IncludedItemsList: [],
  IsOrgProfile: true,
  IsOrgProfileHomeClick: false,
  IsOrganizationLoad: false
}

const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_Business_Details:
      return {
        ...state,
        BusinessDetails: action.data
      }
    case GET_Organization_Details:
      return {
        ...state,
        OrganizationDetails: action.data
      }
    case IS_PREVIEW_DISABLED:
      return {
        ...state,
        isPreviewDisabled: action.data
      }
    case EXCLUDED_OFFER_ITEMS:
      return {
        ...state,
        ExcludedItemsList: action.data
      }
    case INCLUDED_OFFER_ITEMS:
      return {
        ...state,
        IncludedItemsList: action.data
      }
    case IS_Organization_PRofile:
      return {
        ...state,
        IsOrgProfile: action.data
      }
    case IS_Organization_PRofile_Home_Click:
      return {
        ...state,
        IsOrgProfileHomeClick: action.data
      }
    case IS_Organization_Load:
      return {
        ...state,
        IsOrganizationLoad: action.data
      }
    default:
      return state
  }
}

export default organizationReducer
