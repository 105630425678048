import { useDispatch } from "react-redux"
import { Nav, NavItem, NavLink } from "reactstrap"
import { GetMediaGalleryAction } from "../../../redux/actions/mediagallery"

export function MediaGalleryTabs({toggle, active, parentGuid, parentMedia}) {
    const dispacth = useDispatch()
    return <Nav className='justify-content-center' pills>
        <NavItem>
            <NavLink
                active={active === '4'}
                onClick={() => {
                    toggle('4')
                    dispacth(GetMediaGalleryAction(parentGuid, parentMedia, 4))
                }}
            >
                Audio
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink
                active={active === '2'}
                onClick={() => {
                    toggle('2')
                    dispacth(GetMediaGalleryAction(parentGuid, parentMedia, 2))
                }}
            >
                Video
            </NavLink>
        </NavItem>
        <NavItem>
            <NavLink
                active={active === '3'}
                onClick={() => {
                    toggle('3')
                    dispacth(GetMediaGalleryAction(parentGuid, parentMedia, 3))
                }}
            >
                Document
            </NavLink>
        </NavItem>
    </Nav>
}