import { LOADING_FOR_API_CLICK, LOADING_FOR_API_CLICK_CONFLICT } from "../../constants/Spinner"

// ** Initial State
const initialState = {
  isloading: false,
  isconflictloading:false
}

const CreateTripReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_FOR_API_CLICK:
      return { ...state, isloading: action.data }
      case LOADING_FOR_API_CLICK_CONFLICT:
        return { ...state, isconflictloading: action.data }
    default:
      return state
  }
}

export default CreateTripReducer
