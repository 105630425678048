import LanguageList from '../../../assets/data/locales/languages.json'

export const GetLanuageTitle = (lcode) => {
    let lang = ''
    LanguageList.filter(l => {
        if (l.code === lcode) {
            lang = l.name 
        }
    })
    return lang
}