import {
  GET_MEDIA_GALLERY,
  IS_Media_GalleryOpen,
  SET_MEDIA_PGUID_PMEDIA
} from "../../constants/MediaGallery"

// ** Initial State
const initialState = {
  mediagallery: [],
  success: false,
  isMediaGallery: false,
  parentMedia: "",
  parentGuid: ""
}

const MediaGalleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDIA_GALLERY:
      return {
        ...state,
        mediagallery: action.data.MediaItems,
        success: true
      }
    case IS_Media_GalleryOpen:
      return {
        ...state,
        isMediaGallery: action.data
      }
    case SET_MEDIA_PGUID_PMEDIA:
      return {
        ...state,
        parentGuid: action.data?.parentGuid,
        parentMedia: action.data?.parentMedia
      }
    default:
      return state
  }
}

export default MediaGalleryReducer
