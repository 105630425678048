import React, { useState } from "react"
import { useForm } from "react-hook-form"
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  CustomInput,
  Spinner
} from "reactstrap"
import styles from "../../../styles/template.module.scss"
import ModalStyle from "../../../styles/Modals/modals.module.scss"
import { Edit, Trash } from "react-feather"
import LanguageList from "../../../assets/data/locales/languages.json"
import { useDispatch, useSelector } from "react-redux"
import FileBase64 from "react-file-base64"
import axios from "axios"
import { toast } from "react-toastify"
import { GlobalInfoTextColor } from "../../../GlobalAssets/AssetsList"
import { FaTimes } from "react-icons/fa"
import Select from "react-select"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { BASE_MEDIA } from "../../../GlobalURL/URL"
import { CompressVideoAudioDoc } from "../CommFunctions/CompressVideoAudioDoc"
import { GetLanuageTitle } from "../CommFunctions/GetLanguageTitle"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer"
import { DetailDialogTitleMsg } from "../../GlobalInfoDailog/DetailDialogString"
import { GET_MEDIA_GALLERY } from "../../../redux/constants/MediaGallery"
import { Loader, LoadingOverlay } from "react-overlay-loader"
import "react-overlay-loader/styles.css"
import { MediaTypeView } from "../../Items/common/MediaGalleryItems/MediaTypeView"
import ApiButtonTitle from "../../Items/common/ApiButton/ApiButtonTitle"
import { LOADING_FOR_API_CLICK } from "../../../redux/constants/Spinner"

const AddMediaGalleryModal = ({
  setBComStates,
  comBStates,
  parentGuid,
  mediaParent
}) => {
  const {
    active,
    mediaNameTag,
    MediaFile,
    mediaFileUrl,
    language,
    addMediaModalOpen,
    isAddDialog,
    mediaGuid,
    mediaFileName,
    addedTime,
    isPublic,
    IsDefault,
    DataisLoaded,
    mediaType
  } = comBStates
  const dispatch = useDispatch()
  const { isloading } = useSelector((state) => state?.loading)

  const MediaTypeOptions = [
    {
      Label: "Audio",
      Value: 4
    },
    {
      Label: "Video",
      Value: 2
    },
    {
      Label: "Document",
      Value: 3
    }
  ]
  const AddUpdateMediaPromise = (postData) => {
    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        accept: "*/*",
        Authorization: JSON.parse(localStorage.getItem("Token"))
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_MEDIA}/UpdateMediaItems`, postData, axiosConfig)
        .then((res) => resolve(res.data))
        .catch((err) => {
          setBComStates({
            ...comBStates,
            addMediaModalOpen: false,
            active,
            DataisLoaded: false
          })
          reject()
        })
    })
  }
  const OnAddEditRemoveMedia = async (data) => {
    if (mediaFileUrl === null) {
      setBComStates({ ...comBStates, active, DataisLoaded: false })
      toast.error("Please select the media file")
      return false
    }
    let postDP = null

    if (isAddDialog) {
      postDP = {
        parentGuid,
        mediaParent,
        mediaItems: [
          {
            mediaFileTempId: null,
            mediaFileUrl: null,
            mediaThumbUrl: null,
            mediaType: Number(active),
            mediaBytes: null,
            mediaEncoded: MediaFile
              ? mediaFileUrl?.substring(mediaFileUrl.indexOf("base64,") + 7)
              : null,
            mediaFileName,
            isPublic,
            addedTime,
            mediaExtension: MediaFile ? MediaFile?.type : null,
            isRemoved: false,
            mediaNameTag,
            parentGuid,
            mediaParent,
            language,
            mediaGuid,
            IsDefault,
            isPublic
          }
        ]
      }
    } else {
      postDP = {
        parentGuid,
        mediaParent,
        mediaItems: [
          {
            mediaFileName: MediaFile?.name,
            mediaFileTempId: null,
            mediaFileUrl: null,
            mediaThumbUrl: null,
            mediaType: Number(active),
            mediaExtension: MediaFile?.type,
            mediaBytes: null,
            mediaEncoded: mediaFileUrl.substring(
              mediaFileUrl.indexOf("base64,") + 7
            ),
            isRemoved: false,
            mediaNameTag,
            parentGuid,
            mediaParent,
            language,
            IsDefault,
            isPublic
          }
        ]
      }
    }
    dispatch({ type: LOADING_FOR_API_CLICK, data: true })
    await AddUpdateMediaPromise(postDP)
      .then((res) => {
        if (res.ShowMsg) {
          setBComStates({
            ...comBStates,
            addMediaModalOpen: false,
            active,
            DataisLoaded: false
          })
          dispatch({ type: LOADING_FOR_API_CLICK, data: false })
          return toast.error(res.ErrorMsg)
        } else {
          const obj = {
            MediaItems: res.MediaItems.filter(
              (media) => media.MediaType === Number(active)
            )
          }
          dispatch({ type: GET_MEDIA_GALLERY, data: obj })
          if (res.IsSuccess) {
            setBComStates({
              ...comBStates,
              addMediaModalOpen: false,
              active,
              DataisLoaded: false
            })
            dispatch({ type: LOADING_FOR_API_CLICK, data: false })
            toast.success(
              `Media Item has been ${
                isAddDialog ? "Updated" : "Added"
              } Successfully`
            )
          }
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch({ type: LOADING_FOR_API_CLICK, data: false })
      })
  }
  const getFile = (e) => {
    CompressVideoAudioDoc(e, setBComStates, comBStates, active)
  }
  const functionThatResetsTheFileInput = () => {
    const randomString = Math.random().toString(36)
    setBComStates({
      ...comBStates,
      mediaFileUrl: "",
      thumb: null,
      regular: null,
      inputkey: randomString
    })
  }
  const GetMediaTypeLabel = (no) => {
    let lable = ""
    if (no === 4) {
      lable = "Audio"
    }
    if (no === 2) {
      lable = "Video"
    }
    if (no === 3) {
      lable = "Document"
    }
    return lable
  }
  const GetMediaTypeValue = (no) => {
    let value = ""
    if (no === 4) {
      value = no
    }
    if (no === 2) {
      value = no
    }
    if (no === 3) {
      value = no
    }
    return value
  }
  return (
    <div>
      <Modal
        className={`modal-dialog-centered ${ModalStyle.WideModal}`}
        isOpen={addMediaModalOpen}
        style={{ maxWidth: "90vw" }}
        toggle={() =>
          setBComStates({ ...comBStates, addMediaModalOpen: false })
        }
      >
        <ModalHeader
          toggle={() =>
            setBComStates({ ...comBStates, addMediaModalOpen: false })
          }
        >
          {isAddDialog ? "Edit" : "Add"} Media
        </ModalHeader>
        <ModalBody>
          <LoadingOverlay>
            <Form>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <FormGroup>
                        <div className={styles.DragDropModal}>
                          {mediaFileUrl !== null ? (
                            MediaTypeView(Number(active), mediaFileUrl, false)
                          ) : (
                            <p className={styles.DragText}>
                              Drag & Drop Media File (Max size: 25 MB)
                            </p>
                          )}
                          {mediaFileUrl ? null : (
                            <FormGroup className={styles.DropInputModal}>
                              <FileBase64
                                multiple={true}
                                onDone={(e) => getFile(e)}
                                key={Math.random()}
                              />
                            </FormGroup>
                          )}

                          <div style={{ position: "absolute" }}>
                            {mediaFileUrl ? (
                              <Button.Ripple
                                onClick={() => {
                                  functionThatResetsTheFileInput()
                                  setBComStates({
                                    ...comBStates,
                                    mediaFileUrl: null,
                                    file: null
                                  })
                                }}
                                className="btn-icon btn-sm"
                                color="danger"
                              >
                                <Trash size={16} />
                              </Button.Ripple>
                            ) : null}
                          </div>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup
                        className="mt-2"
                        style={{ position: "relative" }}
                      >
                        <Label for="mediaFileUrl Name">Media Name</Label>
                        {mediaNameTag !== null ? (
                          <span
                            onClick={() =>
                              setBComStates({
                                ...comBStates,
                                mediaNameTag: null
                              })
                            }
                            style={{ marginTop: "2px" }}
                            className={styles.clearbtnSE}
                          >
                            <FaTimes fontSize={15} />
                          </span>
                        ) : null}
                        <Input
                          value={mediaNameTag || ""}
                          id="mediaFileUrl Name"
                          name="mediaFileUrl Name"
                          placeholder="Media Name"
                          onChange={(e) =>
                            setBComStates({
                              ...comBStates,
                              mediaNameTag: e.target.value
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="mediaType">Media Type</Label>

                        <Select
                          isDisabled={true}
                          placeholder={GetMediaTypeLabel(Number(active))}
                          value={GetMediaTypeValue(Number(active))}
                          options={MediaTypeOptions}
                          onChange={(e) =>
                            setBComStates({ ...comBStates, mediaType: e.Value })
                          }
                          getOptionValue={(opt) => opt.SearchTag}
                          getOptionLabel={(e) => (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span style={{ marginLeft: 5 }}>{e.Label}</span>
                            </div>
                          )}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="mediaFileUrl Name">Language</Label>

                        <Select
                          name="code"
                          options={LanguageList}
                          value={GetLanuageTitle(language)}
                          onChange={(e) =>
                            setBComStates({
                              ...comBStates,
                              languageName: e.name,
                              language: e.code
                            })
                          }
                          placeholder={GetLanuageTitle(language)}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.name}
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            marginRight: "10px"
                          }}
                        >
                          <small
                            style={{ marginRight: "3px" }}
                            className={GlobalInfoTextColor}
                            for="cityMulti"
                          >
                            Is Publicly Visible?
                          </small>
                          <AiOutlineInfoCircle
                            size={15}
                            onClick={() => {
                              setBComStates({
                                ...comBStates,
                                detailModal: true,
                                detailDialogData: DetailDialogTitleMsg[32]
                              })
                            }}
                          />
                        </div>
                        <CustomInput
                          className="custom-control-primary float-right m-0"
                          type="switch"
                          id={"mediaGallery"}
                          name="primary"
                          inline
                          defaultChecked={isPublic}
                          onChange={(e) =>
                            setBComStates({
                              ...comBStates,
                              isPublic: !isPublic
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup className="mt-2">
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            marginRight: "10px"
                          }}
                        >
                          <small
                            style={{ marginRight: "3px" }}
                            className={GlobalInfoTextColor}
                            for="cityMulti"
                          >
                            Is Default?
                          </small>
                          <AiOutlineInfoCircle
                            size={15}
                            onClick={() => {
                              setBComStates({
                                ...comBStates,
                                detailModal: true,
                                detailDialogData: DetailDialogTitleMsg[35]
                              })
                            }}
                          />
                        </div>
                        <CustomInput
                          className="custom-control-primary float-right m-0"
                          type="switch"
                          id={"IsDefault"}
                          name="primary"
                          inline
                          defaultChecked={IsDefault}
                          onChange={(e) =>
                            setBComStates({
                              ...comBStates,
                              IsDefault: !IsDefault
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Col className="d-flex justifyContent-center mt-3">
                <Col lg="6">
                  <FormGroup className="">
                    <Button.Ripple
                      onClick={() => {
                        setBComStates({
                          ...comBStates,
                          addMediaModalOpen: false
                        })
                      }}
                      className="w-100"
                      color="primary"
                      type="button"
                    >
                      Cancel
                    </Button.Ripple>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup className="">
                    <Button.Ripple
                      className="w-100"
                      color="primary"
                      onClick={() => {
                        setBComStates({ ...comBStates, DataisLoaded: true })
                        OnAddEditRemoveMedia()
                      }}
                      disabled={isloading}
                    >
                      {isAddDialog ? (
                        <ApiButtonTitle title={"Update"} />
                      ) : (
                        <ApiButtonTitle title={"Add"} />
                      )}
                    </Button.Ripple>
                  </FormGroup>
                </Col>
              </Col>
            </Form>
            <Loader loading={DataisLoaded} />
          </LoadingOverlay>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AddMediaGalleryModal
