import imageCompression from "browser-image-compression"
import { toast } from "react-toastify"

export const GetFile = (e, setTempData, TempData, checkStatus) => {
  const file = e[0]?.file
  if (
    e[0].type === "image/jpeg" ||
    e[0].type === "image/png" ||
    e[0].type === "image/jpg" ||
    e[0].type === "image/bmp" ||
    e[0].type === "image/webp"
  ) {
    if (
      e[0].type === "image/png" ||
      e[0].type === "image/webp" ||
      e[0].type === "image/bmp"
    ) {
      if (file.size > 1500000) {
        async function handleImageUpload(event) {
          const imageFile = event
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            initialQuality: 0.7,
            fileType: "image/jpeg"
          }
          try {
            const compressedFile = await imageCompression(imageFile, options)
            // console.log(`File size ${e[0].size} MB`)
            // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`)
            if (checkStatus === "simage") {
              imageCompression.getDataUrlFromFile(compressedFile).then((res) =>
                setTempData({
                  ...TempData,
                  addStartImage: res,
                  addStartFile: compressedFile
                })
              )
            } else if (checkStatus === "eimage") {
              imageCompression.getDataUrlFromFile(compressedFile).then((res) =>
                setTempData({
                  ...TempData,
                  addEndImage: res,
                  addEndFile: compressedFile
                })
              )
            } else if (checkStatus === "wayimage") {
              imageCompression.getDataUrlFromFile(compressedFile).then((res) =>
                setTempData({
                  ...TempData,
                  wayimage: res,
                  wayfile: compressedFile
                })
              )
            } else if (checkStatus === "TripImage") {
              imageCompression.getDataUrlFromFile(compressedFile).then((res) =>
                setTempData({
                  ...TempData,
                  imageTrip: res,
                  fileTrip: compressedFile
                })
              )
            } else if (checkStatus === "updateway") {
              imageCompression.getDataUrlFromFile(compressedFile).then((res) =>
                setTempData({
                  ...TempData,
                  waypimage: res,
                  waypfile: compressedFile
                })
              )
            } else {
              imageCompression
                .getDataUrlFromFile(compressedFile)
                .then((res) =>
                  setTempData({ ...TempData, image: res, file: compressedFile })
                )
            }
          } catch (error) {
            console.log(error)
          }
        }
        handleImageUpload(file)
      } else {
        if (checkStatus === "simage") {
          setTempData({
            ...TempData,
            addStartImage: e[0].base64,
            addStartFile: e[0].file
          })
        } else if (checkStatus === "eimage") {
          setTempData({
            ...TempData,
            addEndImage: e[0].base64,
            addEndFile: e[0].file
          })
        } else if (checkStatus === "wayimage") {
          setTempData({
            ...TempData,
            wayimage: e[0].base64,
            wayfile: e[0].file
          })
        } else if (checkStatus === "TripImage") {
          setTempData({
            ...TempData,
            imageTrip: e[0].base64,
            fileTrip: e[0].file
          })
        } else if (checkStatus === "updateway") {
          setTempData({
            ...TempData,
            waypimage: e[0].base64,
            waypfile: e[0].file
          })
        } else if (checkStatus === "OrganizationCover") {
          setTempData({
            ...TempData,
            OrganizationCoverImage: e[0].base64,
            OCfile: e[0].file
          })
        } else if (checkStatus === "OrganizationPro") {
          setTempData({
            ...TempData,
            OrganizationImage: e[0].base64,
            Ofile: e[0]?.file
          })
        } else {
          setTempData({ ...TempData, image: e[0].base64, file: e[0].file })
        }
      }
    } else {
      if (checkStatus === "simage") {
        setTempData({
          ...TempData,
          addStartImage: e[0].base64,
          addStartFile: e[0].file
        })
      } else if (checkStatus === "eimage") {
        setTempData({
          ...TempData,
          addEndImage: e[0].base64,
          addEndFile: e[0].file
        })
      } else if (checkStatus === "wayimage") {
        setTempData({ ...TempData, wayimage: e[0].base64, wayfile: e[0].file })
      } else if (checkStatus === "TripImage") {
        setTempData({
          ...TempData,
          imageTrip: e[0].base64,
          fileTrip: e[0].file
        })
      } else if (checkStatus === "OrganizationCover") {
        setTempData({
          ...TempData,
          OrganizationCoverImage: e[0].base64,
          OCfile: e[0].file
        })
      } else if (checkStatus === "OrganizationPro") {
        setTempData({
          ...TempData,
          OrganizationImage: e[0].base64,
          Ofile: e[0].file
        })
      } else if (checkStatus === "updateway") {
        setTempData({
          ...TempData,
          waypimage: e[0].base64,
          waypfile: e[0].file
        })
      } else {
        setTempData({ ...TempData, image: e[0].base64, file: e[0].file })
      }
    }
  } else {
    toast.error("only .jpeg,.jpg,.png, and .webp formats are allowed")
  }
}
export async function GetCompressedFile(e, setImage, setFile) {
  const file = e[0].file
  if (
    e[0].type === "image/jpeg" ||
    e[0].type === "image/png" ||
    e[0].type === "image/jpg" ||
    e[0].type === "image/bmp" ||
    e[0].type === "image/webp"
  ) {
    if (
      e[0].type === "image/png" ||
      e[0].type === "image/webp" ||
      e[0].type === "image/bmp"
    ) {
      if (file.size > 1500000) {
        async function handleImageUpload(event) {
          const imageFile = event
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            initialQuality: 0.7,
            fileType: "image/jpeg"
          }
          try {
            const compressedFile = await imageCompression(imageFile, options)
            // console.log(`File size ${e[0].size}`)
            // console.log(`compressedFile size ${compressedFile.size / 1024} KB`)
            imageCompression.getDataUrlFromFile(compressedFile).then((res) => {
              setImage(res)
              setFile(compressedFile)
            })
          } catch (error) {
            console.log(error)
          }
        }
        handleImageUpload(file)
      } else {
        setImage(e[0].base64)
        setFile(e[0].file)
      }
    } else {
      setImage(e[0].base64)
      setFile(e[0].file)
    }
  } else {
    toast.error("only .jpeg,.jpg,.png, and .webp formats are allowed")
  }
}

export async function GetCompressedFileOrganization(
  e,
  setImageTirp,
  setFileTrip
) {
  const file = e[0].file
  if (
    e[0].type === "image/jpeg" ||
    e[0].type === "image/png" ||
    e[0].type === "image/jpg" ||
    e[0].type === "image/bmp" ||
    e[0].type === "image/webp"
  ) {
    if (e[0].type === "image/webp" || e[0].type === "image/bmp") {
      if (file.size > 1500000) {
        async function handleImageUpload(event) {
          const imageFile = event
          const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            initialQuality: 0.7,
            fileType: "image/png"
          }
          try {
            const compressedFile = await imageCompression(imageFile, options)
            // console.log(`File size ${e[0].size}`)
            // console.log(`compressedFile size ${compressedFile.size } KB`)
            imageCompression.getDataUrlFromFile(compressedFile).then((res) => {
              setImageTirp(res)
              setFileTrip(compressedFile)
            })
          } catch (error) {
            console.log(error)
          }
        }
        handleImageUpload(file)
      } else {
        setImageTirp(e[0].base64)
        setFileTrip(e[0].file)
      }
    } else {
      setImageTirp(e[0].base64)
      setFileTrip(e[0].file)
    }
  } else {
    toast.error("only .jpeg,.jpg,.png, and .webp formats are allowed")
  }
}
